.homepage-container {
    min-width: 850px;
}

.brand-header-home {
    margin-top: unset;
    margin-bottom: 10px;
}

.panel-container-home {
    display: table;
    min-width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    border-collapse: separate;
    border-spacing: 1.5rem 0rem;
    margin-left: -1.5rem;
}

.button-container {
    display: flex;
    margin-top: 2.5vh;
    gap: 2%;
    margin-bottom: 1%;
}

.panels {
    display: table-cell;
    background-color: #f3f3f3;
    width: 50%;
    min-height: 100px;
}

.panel-content {
    width: 100%;
    display: flex;

    .Appkit4-icon {
        display: inline-block;
        font-size: 50px;
        vertical-align: bottom;
        align-items: center;
        margin-top: 12%;
        width: 25%;
        color: #d04a02;
        margin-left: 1.5rem;
    }

    .ap-loading {
        justify-content: unset;
    }

    .panel-text {
        display: inline-block;
        vertical-align: top;
        max-width: calc(100%);
        overflow-wrap: break-word;
        margin-top: 1.5rem;
    }

    .rule-text {
        padding-left: 0.5rem;
    }
}

.brand-text-subtitle {
    margin-top: 1%;
    margin-bottom: 1%;
}
