@import '/node_modules/sass-rem/_rem.scss';
@import '/node_modules/@appkit4/react-components/styles/scss/_variables';

.ap-navigation-wrapper {
    background-color: $color-background-default;
    position: sticky;
    top: 4rem;
    height: 90vh;
    width: unset;
    margin-right: 1rem;
}

.ap-navigation-item.selected,
.ap-navigation-item.active {
    font-weight: $font-weight-2;
    background-color: $color-background-hover-selected !important;

    .prefix-content .Appkit4-icon::before {
    }

    .suffixIcon.Appkit4-icon::before {
    }
}

.ap-navigation-content,
.ap-navigation-footer {
    border-right: 1px solid #c5c5c5;
}

.ap-navigation-wrapper:not(.solidnav) {
    background-color: unset;
}
