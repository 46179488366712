.pwc-brand-icon {
    display: inline-flex;
    width: 24px;
    height: 24px;

    &-small {
        @extend .pwc-brand-icon;
        width: 16px;
        height: 16px;
    }

    &-large {
        @extend .pwc-brand-icon;
        width: 32px;
        height: 32px;
    }

    &-xlarge {
        @extend .pwc-brand-icon;
        width: 40px;
        height: 40px;
    }
}

.icon-surveyscale3 {
    mask: url(./SurveyScale3.svg);
    -webkit-mask: url(./SurveyScale3.svg);
}

.icon-disabilityinsurance {
    mask: url(./DisabilityInsurance.svg);
    -webkit-mask: url(./DisabilityInsurance.svg);
}

.icon-keychange {
    mask: url(./KeyChange.svg);
    -webkit-mask: url(./KeyChange.svg);
}

.icon-airfilter {
    mask: url(./AirFilter.svg);
    -webkit-mask: url(./AirFilter.svg);
}

.icon-timelapse {
    mask: url(./TimeLapse.svg);
    -webkit-mask: url(./TimeLapse.svg);
}

.icon-hand {
    mask: url(./Hand.svg);
    -webkit-mask: url(./Hand.svg);
}

.icon-search {
    mask: url(./Search.svg);
    -webkit-mask: url(./Search.svg);
}

.icon-typography-fonts {
    mask: url(./Typography-Fonts.svg);
    -webkit-mask: url(./Typography-Fonts.svg);
}

.icon-circlealert {
    mask: url(./CircleAlert.svg);
    -webkit-mask: url(./CircleAlert.svg);
}

.icon-sideeyes {
    mask: url(./SideEyes.svg);
    -webkit-mask: url(./SideEyes.svg);
}

.icon-management {
    mask: url(./Management.svg);
    -webkit-mask: url(./Management.svg);
}

.icon-platforms {
    mask: url(./Platforms.svg);
    -webkit-mask: url(./Platforms.svg);
}

.icon-checkmark {
    mask: url(./Checkmark.svg);
    -webkit-mask: url(./Checkmark.svg);
}

.icon-calendarclock {
    mask: url(./CalendarClock.svg);
    -webkit-mask: url(./CalendarClock.svg);
}

.icon-payment {
    mask: url(./Payment.svg);
    -webkit-mask: url(./Payment.svg);
}

.icon-rotateclockwise {
    mask: url(./RotateClockwise.svg);
    -webkit-mask: url(./RotateClockwise.svg);
}

.icon-circledelete {
    mask: url(./CircleDelete.svg);
    -webkit-mask: url(./CircleDelete.svg);
}

.icon-configuration {
    mask: url(./Configuration.svg);
    -webkit-mask: url(./Configuration.svg);
}

.icon-prescription {
    mask: url(./Prescription.svg);
    -webkit-mask: url(./Prescription.svg);
}

.icon-immersivetechnology {
    mask: url(./ImmersiveTechnology.svg);
    -webkit-mask: url(./ImmersiveTechnology.svg);
}

.icon-savings {
    mask: url(./Savings.svg);
    -webkit-mask: url(./Savings.svg);
}

.icon-downloadsimple {
    mask: url(./DownloadSimple.svg);
    -webkit-mask: url(./DownloadSimple.svg);
}

.icon-target {
    mask: url(./Target.svg);
    -webkit-mask: url(./Target.svg);
}

.icon-information {
    mask: url(./Information.svg);
    -webkit-mask: url(./Information.svg);
}

.icon-lockers {
    mask: url(./Lockers.svg);
    -webkit-mask: url(./Lockers.svg);
}

.icon-extend {
    mask: url(./Extend.svg);
    -webkit-mask: url(./Extend.svg);
}

.icon-documentpage {
    mask: url(./DocumentPage.svg);
    -webkit-mask: url(./DocumentPage.svg);
}

.icon-surveyscale2 {
    mask: url(./SurveyScale2.svg);
    -webkit-mask: url(./SurveyScale2.svg);
}

.icon-downloadsave {
    mask: url(./DownloadSave.svg);
    -webkit-mask: url(./DownloadSave.svg);
}

.icon-atm {
    mask: url(./ATM.svg);
    -webkit-mask: url(./ATM.svg);
}

.icon-chevrondoubledown {
    mask: url(./ChevronDoubleDown.svg);
    -webkit-mask: url(./ChevronDoubleDown.svg);
}

.icon-shoppingcart {
    mask: url(./ShoppingCart.svg);
    -webkit-mask: url(./ShoppingCart.svg);
}

.icon-dining {
    mask: url(./Dining.svg);
    -webkit-mask: url(./Dining.svg);
}

.icon-revert {
    mask: url(./Revert.svg);
    -webkit-mask: url(./Revert.svg);
}

.icon-bus-publictransportation {
    mask: url(./Bus-PublicTransportation.svg);
    -webkit-mask: url(./Bus-PublicTransportation.svg);
}

.icon-linkbroken {
    mask: url(./LinkBroken.svg);
    -webkit-mask: url(./LinkBroken.svg);
}

.icon-lifeinsurance {
    mask: url(./LifeInsurance.svg);
    -webkit-mask: url(./LifeInsurance.svg);
}

.icon-vennabc {
    mask: url(./VennABC.svg);
    -webkit-mask: url(./VennABC.svg);
}

.icon-growrevenue {
    mask: url(./GrowRevenue.svg);
    -webkit-mask: url(./GrowRevenue.svg);
}

.icon-circleblank {
    mask: url(./CircleBlank.svg);
    -webkit-mask: url(./CircleBlank.svg);
}

.icon-vennabhorizontal {
    mask: url(./VennABHorizontal.svg);
    -webkit-mask: url(./VennABHorizontal.svg);
}

.icon-businessservices-printer2 {
    mask: url(./BusinessServices-Printer2.svg);
    -webkit-mask: url(./BusinessServices-Printer2.svg);
}

.icon-powerbi {
    mask: url(./PowerBI.svg);
    -webkit-mask: url(./PowerBI.svg);
}

.icon-sitelink {
    mask: url(./SiteLink.svg);
    -webkit-mask: url(./SiteLink.svg);
}

.icon-folderopenangled {
    mask: url(./FolderOpenAngled.svg);
    -webkit-mask: url(./FolderOpenAngled.svg);
}

.icon-person {
    mask: url(./Person.svg);
    -webkit-mask: url(./Person.svg);
}

.icon-home {
    mask: url(./Home.svg);
    -webkit-mask: url(./Home.svg);
}

.icon-surveyscale1 {
    mask: url(./SurveyScale1.svg);
    -webkit-mask: url(./SurveyScale1.svg);
}

.icon-digitaltechtransformation {
    mask: url(./DigitalTechTransformation.svg);
    -webkit-mask: url(./DigitalTechTransformation.svg);
}

.icon-surveyscale5 {
    mask: url(./SurveyScale5.svg);
    -webkit-mask: url(./SurveyScale5.svg);
}

.icon-automobile {
    mask: url(./Automobile.svg);
    -webkit-mask: url(./Automobile.svg);
}

.icon-alert {
    mask: url(./Alert.svg);
    -webkit-mask: url(./Alert.svg);
}

.icon-creditcard {
    mask: url(./CreditCard.svg);
    -webkit-mask: url(./CreditCard.svg);
}

.icon-mailbox {
    mask: url(./Mailbox.svg);
    -webkit-mask: url(./Mailbox.svg);
}

.icon-train-railway {
    mask: url(./Train-Railway.svg);
    -webkit-mask: url(./Train-Railway.svg);
}

.icon-smartphone {
    mask: url(./Smartphone.svg);
    -webkit-mask: url(./Smartphone.svg);
}

.icon-notifications-alert {
    mask: url(./Notifications-Alert.svg);
    -webkit-mask: url(./Notifications-Alert.svg);
}

.icon-family {
    mask: url(./Family.svg);
    -webkit-mask: url(./Family.svg);
}

.icon-library {
    mask: url(./Library.svg);
    -webkit-mask: url(./Library.svg);
}

.icon-apparel {
    mask: url(./Apparel.svg);
    -webkit-mask: url(./Apparel.svg);
}

.icon-logout {
    mask: url(./Logout.svg);
    -webkit-mask: url(./Logout.svg);
}

.icon-shieldoff {
    mask: url(./ShieldOff.svg);
    -webkit-mask: url(./ShieldOff.svg);
}

.icon-4lframework {
    mask: url(./4LFramework.svg);
    -webkit-mask: url(./4LFramework.svg);
}

.icon-sortdescent {
    mask: url(./SortDescent.svg);
    -webkit-mask: url(./SortDescent.svg);
}

.icon-profitability {
    mask: url(./Profitability.svg);
    -webkit-mask: url(./Profitability.svg);
}

.icon-calendaraccepted {
    mask: url(./CalendarAccepted.svg);
    -webkit-mask: url(./CalendarAccepted.svg);
}

.icon-spiderchart {
    mask: url(./SpiderChart.svg);
    -webkit-mask: url(./SpiderChart.svg);
}

.icon-documentsend {
    mask: url(./DocumentSend.svg);
    -webkit-mask: url(./DocumentSend.svg);
}

.icon-signpost {
    mask: url(./Signpost.svg);
    -webkit-mask: url(./Signpost.svg);
}

.icon-sections {
    mask: url(./Sections.svg);
    -webkit-mask: url(./Sections.svg);
}

.icon-ticket {
    mask: url(./Ticket.svg);
    -webkit-mask: url(./Ticket.svg);
}

.icon-cookie {
    mask: url(./Cookie.svg);
    -webkit-mask: url(./Cookie.svg);
}

.icon-tag {
    mask: url(./Tag.svg);
    -webkit-mask: url(./Tag.svg);
}

.icon-windmill {
    mask: url(./Windmill.svg);
    -webkit-mask: url(./Windmill.svg);
}

.icon-linkunlinked {
    mask: url(./LinkUnlinked.svg);
    -webkit-mask: url(./LinkUnlinked.svg);
}

.icon-touchscreen {
    mask: url(./Touchscreen.svg);
    -webkit-mask: url(./Touchscreen.svg);
}

.icon-permittedwithcaution {
    mask: url(./PermittedWithCaution.svg);
    -webkit-mask: url(./PermittedWithCaution.svg);
}

.icon-lockopen-unlock {
    mask: url(./LockOpen-Unlock.svg);
    -webkit-mask: url(./LockOpen-Unlock.svg);
}

.icon-surveyscale4 {
    mask: url(./SurveyScale4.svg);
    -webkit-mask: url(./SurveyScale4.svg);
}

.icon-vector {
    mask: url(./Vector.svg);
    -webkit-mask: url(./Vector.svg);
}

.icon-cloudloading {
    mask: url(./CloudLoading.svg);
    -webkit-mask: url(./CloudLoading.svg);
}

.icon-shower {
    mask: url(./Shower.svg);
    -webkit-mask: url(./Shower.svg);
}

.icon-map {
    mask: url(./Map.svg);
    -webkit-mask: url(./Map.svg);
}

.icon-globemap {
    mask: url(./GlobeMap.svg);
    -webkit-mask: url(./GlobeMap.svg);
}

.icon-community {
    mask: url(./Community.svg);
    -webkit-mask: url(./Community.svg);
}

.icon-microphone {
    mask: url(./Microphone.svg);
    -webkit-mask: url(./Microphone.svg);
}

.icon-database {
    mask: url(./Database.svg);
    -webkit-mask: url(./Database.svg);
}

.icon-flashdrive {
    mask: url(./FlashDrive.svg);
    -webkit-mask: url(./FlashDrive.svg);
}

.icon-change {
    mask: url(./Change.svg);
    -webkit-mask: url(./Change.svg);
}

.icon-stewardship {
    mask: url(./Stewardship.svg);
    -webkit-mask: url(./Stewardship.svg);
}

.icon-transform {
    mask: url(./Transform.svg);
    -webkit-mask: url(./Transform.svg);
}

.icon-file {
    mask: url(./File.svg);
    -webkit-mask: url(./File.svg);
}

.icon-duplicate {
    mask: url(./Duplicate.svg);
    -webkit-mask: url(./Duplicate.svg);
}

.icon-fireexit {
    mask: url(./FireExit.svg);
    -webkit-mask: url(./FireExit.svg);
}

.icon-governance {
    mask: url(./Governance.svg);
    -webkit-mask: url(./Governance.svg);
}

.icon-talent {
    mask: url(./Talent.svg);
    -webkit-mask: url(./Talent.svg);
}

.icon-chevrondown {
    mask: url(./ChevronDown.svg);
    -webkit-mask: url(./ChevronDown.svg);
}

.icon-service {
    mask: url(./Service.svg);
    -webkit-mask: url(./Service.svg);
}

.icon-strategy {
    mask: url(./Strategy.svg);
    -webkit-mask: url(./Strategy.svg);
}

.icon-shareios {
    mask: url(./ShareIOS.svg);
    -webkit-mask: url(./ShareIOS.svg);
}

.icon-3dimensions {
    mask: url(./3Dimensions.svg);
    -webkit-mask: url(./3Dimensions.svg);
}

.icon-move {
    mask: url(./Move.svg);
    -webkit-mask: url(./Move.svg);
}

.icon-waterdrop {
    mask: url(./WaterDrop.svg);
    -webkit-mask: url(./WaterDrop.svg);
}

.icon-arrowdown {
    mask: url(./ArrowDown.svg);
    -webkit-mask: url(./ArrowDown.svg);
}

.icon-4quadrants {
    mask: url(./4Quadrants.svg);
    -webkit-mask: url(./4Quadrants.svg);
}

.icon-dial-high {
    mask: url(./Dial-High.svg);
    -webkit-mask: url(./Dial-High.svg);
}

.icon-chevrondoubleright {
    mask: url(./ChevronDoubleRight.svg);
    -webkit-mask: url(./ChevronDoubleRight.svg);
}

.icon-keyboard {
    mask: url(./Keyboard.svg);
    -webkit-mask: url(./Keyboard.svg);
}

.icon-engagement {
    mask: url(./Engagement.svg);
    -webkit-mask: url(./Engagement.svg);
}

.icon-doc {
    mask: url(./Doc.svg);
    -webkit-mask: url(./Doc.svg);
}

.icon-businesssuit {
    mask: url(./BusinessSuit.svg);
    -webkit-mask: url(./BusinessSuit.svg);
}

.icon-thumbsup {
    mask: url(./ThumbsUp.svg);
    -webkit-mask: url(./ThumbsUp.svg);
}

.icon-fireextinguisher {
    mask: url(./FireExtinguisher.svg);
    -webkit-mask: url(./FireExtinguisher.svg);
}

.icon-clapping-applause {
    mask: url(./Clapping-Applause.svg);
    -webkit-mask: url(./Clapping-Applause.svg);
}

.icon-pen {
    mask: url(./Pen.svg);
    -webkit-mask: url(./Pen.svg);
}

.icon-powerplant {
    mask: url(./Powerplant.svg);
    -webkit-mask: url(./Powerplant.svg);
}

.icon-audience {
    mask: url(./Audience.svg);
    -webkit-mask: url(./Audience.svg);
}

.icon-accountbox {
    mask: url(./AccountBox.svg);
    -webkit-mask: url(./AccountBox.svg);
}

.icon-clouddelete {
    mask: url(./CloudDelete.svg);
    -webkit-mask: url(./CloudDelete.svg);
}

.icon-savemoney {
    mask: url(./SaveMoney.svg);
    -webkit-mask: url(./SaveMoney.svg);
}

.icon-link {
    mask: url(./Link.svg);
    -webkit-mask: url(./Link.svg);
}

.icon-parks-outdoors {
    mask: url(./Parks-Outdoors.svg);
    -webkit-mask: url(./Parks-Outdoors.svg);
}

.icon-idcard {
    mask: url(./IDCard.svg);
    -webkit-mask: url(./IDCard.svg);
}

.icon-downloadbox {
    mask: url(./DownloadBox.svg);
    -webkit-mask: url(./DownloadBox.svg);
}

.icon-beaker {
    mask: url(./Beaker.svg);
    -webkit-mask: url(./Beaker.svg);
}

.icon-bargraph {
    mask: url(./BarGraph.svg);
    -webkit-mask: url(./BarGraph.svg);
}

.icon-praise-gratitude {
    mask: url(./Praise-Gratitude.svg);
    -webkit-mask: url(./Praise-Gratitude.svg);
}

.icon-ship {
    mask: url(./Ship.svg);
    -webkit-mask: url(./Ship.svg);
}

.icon-qrcode {
    mask: url(./QRCode.svg);
    -webkit-mask: url(./QRCode.svg);
}

.icon-rotatecounterclockwise {
    mask: url(./RotateCounterclockwise.svg);
    -webkit-mask: url(./RotateCounterclockwise.svg);
}

.icon-minimize {
    mask: url(./Minimize.svg);
    -webkit-mask: url(./Minimize.svg);
}

.icon-group {
    mask: url(./Group.svg);
    -webkit-mask: url(./Group.svg);
}

.icon-settings {
    mask: url(./Settings.svg);
    -webkit-mask: url(./Settings.svg);
}

.icon-thumbsup2 {
    mask: url(./ThumbsUp2.svg);
    -webkit-mask: url(./ThumbsUp2.svg);
}

.icon-circleplus {
    mask: url(./CirclePlus.svg);
    -webkit-mask: url(./CirclePlus.svg);
}

.icon-dictionary {
    mask: url(./Dictionary.svg);
    -webkit-mask: url(./Dictionary.svg);
}

.icon-website {
    mask: url(./Website.svg);
    -webkit-mask: url(./Website.svg);
}

.icon-helpquestion {
    mask: url(./HelpQuestion.svg);
    -webkit-mask: url(./HelpQuestion.svg);
}

.icon-realestate {
    mask: url(./RealEstate.svg);
    -webkit-mask: url(./RealEstate.svg);
}

.icon-maplocation {
    mask: url(./MapLocation.svg);
    -webkit-mask: url(./MapLocation.svg);
}

.icon-archive {
    mask: url(./Archive.svg);
    -webkit-mask: url(./Archive.svg);
}

.icon-sortdefault {
    mask: url(./SortDefault.svg);
    -webkit-mask: url(./SortDefault.svg);
}

.icon-notifications-off {
    mask: url(./Notifications-Off.svg);
    -webkit-mask: url(./Notifications-Off.svg);
}

.icon-shield {
    mask: url(./Shield.svg);
    -webkit-mask: url(./Shield.svg);
}

.icon-folderplus {
    mask: url(./FolderPlus.svg);
    -webkit-mask: url(./FolderPlus.svg);
}

.icon-uploadsimple {
    mask: url(./UploadSimple.svg);
    -webkit-mask: url(./UploadSimple.svg);
}

.icon-arrowright {
    mask: url(./ArrowRight.svg);
    -webkit-mask: url(./ArrowRight.svg);
}

.icon-kanban {
    mask: url(./Kanban.svg);
    -webkit-mask: url(./Kanban.svg);
}

.icon-expand {
    mask: url(./Expand.svg);
    -webkit-mask: url(./Expand.svg);
}

.icon-time {
    mask: url(./Time.svg);
    -webkit-mask: url(./Time.svg);
}

.icon-note {
    mask: url(./Note.svg);
    -webkit-mask: url(./Note.svg);
}

.icon-library2-reports {
    mask: url(./Library2-Reports.svg);
    -webkit-mask: url(./Library2-Reports.svg);
}

.icon-umb {
    mask: url(./Umb.svg);
    -webkit-mask: url(./Umb.svg);
}

.icon-hub {
    mask: url(./Hub.svg);
    -webkit-mask: url(./Hub.svg);
}

.icon-projector {
    mask: url(./Projector.svg);
    -webkit-mask: url(./Projector.svg);
}

.icon-truckconnected {
    mask: url(./TruckConnected.svg);
    -webkit-mask: url(./TruckConnected.svg);
}

.icon-xbrl {
    mask: url(./XBRL.svg);
    -webkit-mask: url(./XBRL.svg);
}

.icon-arrowdecrease {
    mask: url(./ArrowDecrease.svg);
    -webkit-mask: url(./ArrowDecrease.svg);
}

.icon-debtfree {
    mask: url(./DebtFree.svg);
    -webkit-mask: url(./DebtFree.svg);
}

.icon-battery-medium {
    mask: url(./Battery-Medium.svg);
    -webkit-mask: url(./Battery-Medium.svg);
}

.icon-genderneutral {
    mask: url(./GenderNeutral.svg);
    -webkit-mask: url(./GenderNeutral.svg);
}

.icon-visitor {
    mask: url(./Visitor.svg);
    -webkit-mask: url(./Visitor.svg);
}

.icon-login {
    mask: url(./Login.svg);
    -webkit-mask: url(./Login.svg);
}

.icon-radio {
    mask: url(./Radio.svg);
    -webkit-mask: url(./Radio.svg);
}

.icon-book {
    mask: url(./Book.svg);
    -webkit-mask: url(./Book.svg);
}

.icon-morevertical {
    mask: url(./MoreVertical.svg);
    -webkit-mask: url(./MoreVertical.svg);
}

.icon-oilpipeline {
    mask: url(./OilPipeline.svg);
    -webkit-mask: url(./OilPipeline.svg);
}

.icon-locationlocked {
    mask: url(./LocationLocked.svg);
    -webkit-mask: url(./LocationLocked.svg);
}

.icon-bot {
    mask: url(./Bot.svg);
    -webkit-mask: url(./Bot.svg);
}

.icon-wallet {
    mask: url(./Wallet.svg);
    -webkit-mask: url(./Wallet.svg);
}

.icon-place {
    mask: url(./Place.svg);
    -webkit-mask: url(./Place.svg);
}

.icon-dashboard {
    mask: url(./Dashboard.svg);
    -webkit-mask: url(./Dashboard.svg);
}

.icon-femalesymbol {
    mask: url(./FemaleSymbol.svg);
    -webkit-mask: url(./FemaleSymbol.svg);
}

.icon-deskchair {
    mask: url(./DeskChair.svg);
    -webkit-mask: url(./DeskChair.svg);
}

.icon-sharebox {
    mask: url(./ShareBox.svg);
    -webkit-mask: url(./ShareBox.svg);
}

.icon-thermometer {
    mask: url(./Thermometer.svg);
    -webkit-mask: url(./Thermometer.svg);
}

.icon-flag {
    mask: url(./Flag.svg);
    -webkit-mask: url(./Flag.svg);
}

.icon-accountremove {
    mask: url(./AccountRemove.svg);
    -webkit-mask: url(./AccountRemove.svg);
}

.icon-update {
    mask: url(./Update.svg);
    -webkit-mask: url(./Update.svg);
}

.icon-art-themes {
    mask: url(./Art-Themes.svg);
    -webkit-mask: url(./Art-Themes.svg);
}

.icon-desktopcomputer {
    mask: url(./DesktopComputer.svg);
    -webkit-mask: url(./DesktopComputer.svg);
}

.icon-clinic {
    mask: url(./Clinic.svg);
    -webkit-mask: url(./Clinic.svg);
}

.icon-accountswitch {
    mask: url(./AccountSwitch.svg);
    -webkit-mask: url(./AccountSwitch.svg);
}

.icon-chevronrightcircle {
    mask: url(./ChevronRightCircle.svg);
    -webkit-mask: url(./ChevronRightCircle.svg);
}

.icon-container {
    mask: url(./Container.svg);
    -webkit-mask: url(./Container.svg);
}

.icon-textbox {
    mask: url(./Textbox.svg);
    -webkit-mask: url(./Textbox.svg);
}

.icon-meeting {
    mask: url(./Meeting.svg);
    -webkit-mask: url(./Meeting.svg);
}

.icon-trenddown {
    mask: url(./TrendDown.svg);
    -webkit-mask: url(./TrendDown.svg);
}

.icon-partnership {
    mask: url(./Partnership.svg);
    -webkit-mask: url(./Partnership.svg);
}

.icon-arrowincrease {
    mask: url(./ArrowIncrease.svg);
    -webkit-mask: url(./ArrowIncrease.svg);
}

.icon-boat {
    mask: url(./Boat.svg);
    -webkit-mask: url(./Boat.svg);
}

.icon-trophy {
    mask: url(./Trophy.svg);
    -webkit-mask: url(./Trophy.svg);
}

.icon-folderdelete {
    mask: url(./FolderDelete.svg);
    -webkit-mask: url(./FolderDelete.svg);
}

.icon-plus {
    mask: url(./Plus.svg);
    -webkit-mask: url(./Plus.svg);
}

.icon-document {
    mask: url(./Document.svg);
    -webkit-mask: url(./Document.svg);
}

.icon-jewelry {
    mask: url(./Jewelry.svg);
    -webkit-mask: url(./Jewelry.svg);
}

.icon-chevronupcircle {
    mask: url(./ChevronUpCircle.svg);
    -webkit-mask: url(./ChevronUpCircle.svg);
}

.icon-battery-high {
    mask: url(./Battery-High.svg);
    -webkit-mask: url(./Battery-High.svg);
}

.icon-hospital {
    mask: url(./Hospital.svg);
    -webkit-mask: url(./Hospital.svg);
}

.icon-tabledata {
    mask: url(./TableData.svg);
    -webkit-mask: url(./TableData.svg);
}

.icon-paperairplan {
    mask: url(./PaperAirplan.svg);
    -webkit-mask: url(./PaperAirplan.svg);
}

.icon-chevronright {
    mask: url(./ChevronRight.svg);
    -webkit-mask: url(./ChevronRight.svg);
}

.icon-malesymbol {
    mask: url(./MaleSymbol.svg);
    -webkit-mask: url(./MaleSymbol.svg);
}

.icon-neighborhood {
    mask: url(./Neighborhood.svg);
    -webkit-mask: url(./Neighborhood.svg);
}

.icon-email {
    mask: url(./Email.svg);
    -webkit-mask: url(./Email.svg);
}

.icon-piechart {
    mask: url(./PieChart.svg);
    -webkit-mask: url(./PieChart.svg);
}

.icon-wifi {
    mask: url(./WiFi.svg);
    -webkit-mask: url(./WiFi.svg);
}

.icon-confetti-celebration {
    mask: url(./Confetti-Celebration.svg);
    -webkit-mask: url(./Confetti-Celebration.svg);
}

.icon-news {
    mask: url(./News.svg);
    -webkit-mask: url(./News.svg);
}

.icon-cloudclock {
    mask: url(./CloudClock.svg);
    -webkit-mask: url(./CloudClock.svg);
}

.icon-documentpolicies {
    mask: url(./DocumentPolicies.svg);
    -webkit-mask: url(./DocumentPolicies.svg);
}

.icon-wealth {
    mask: url(./Wealth.svg);
    -webkit-mask: url(./Wealth.svg);
}

.icon-bubbleplot {
    mask: url(./BubblePlot.svg);
    -webkit-mask: url(./BubblePlot.svg);
}

.icon-labs {
    mask: url(./Labs.svg);
    -webkit-mask: url(./Labs.svg);
}

.icon-arrowdownbold {
    mask: url(./ArrowDownBold.svg);
    -webkit-mask: url(./ArrowDownBold.svg);
}

.icon-survey {
    mask: url(./Survey.svg);
    -webkit-mask: url(./Survey.svg);
}

.icon-speedometer {
    mask: url(./Speedometer.svg);
    -webkit-mask: url(./Speedometer.svg);
}

.icon-documentdownload {
    mask: url(./DocumentDownload.svg);
    -webkit-mask: url(./DocumentDownload.svg);
}

.icon-close {
    mask: url(./Close.svg);
    -webkit-mask: url(./Close.svg);
}

.icon-listview {
    mask: url(./ListView.svg);
    -webkit-mask: url(./ListView.svg);
}

.icon-circlemore {
    mask: url(./CircleMore.svg);
    -webkit-mask: url(./CircleMore.svg);
}

.icon-toggle {
    mask: url(./Toggle.svg);
    -webkit-mask: url(./Toggle.svg);
}

.icon-button {
    mask: url(./Button.svg);
    -webkit-mask: url(./Button.svg);
}

.icon-capital {
    mask: url(./Capital.svg);
    -webkit-mask: url(./Capital.svg);
}

.icon-infinityloop {
    mask: url(./InfinityLoop.svg);
    -webkit-mask: url(./InfinityLoop.svg);
}

.icon-waterleak {
    mask: url(./WaterLeak.svg);
    -webkit-mask: url(./WaterLeak.svg);
}

.icon-mortgagepayment {
    mask: url(./MortgagePayment.svg);
    -webkit-mask: url(./MortgagePayment.svg);
}

.icon-clouddisable {
    mask: url(./CloudDisable.svg);
    -webkit-mask: url(./CloudDisable.svg);
}

.icon-refresh {
    mask: url(./Refresh.svg);
    -webkit-mask: url(./Refresh.svg);
}

.icon-text {
    mask: url(./Text.svg);
    -webkit-mask: url(./Text.svg);
}

.icon-image {
    mask: url(./Image.svg);
    -webkit-mask: url(./Image.svg);
}

.icon-circlestar {
    mask: url(./CircleStar.svg);
    -webkit-mask: url(./CircleStar.svg);
}

.icon-notifications-first {
    mask: url(./Notifications-First.svg);
    -webkit-mask: url(./Notifications-First.svg);
}

.icon-biometrics {
    mask: url(./Biometrics.svg);
    -webkit-mask: url(./Biometrics.svg);
}

.icon-lightbulb {
    mask: url(./Lightbulb.svg);
    -webkit-mask: url(./Lightbulb.svg);
}

.icon-documentfile {
    mask: url(./DocumentFile.svg);
    -webkit-mask: url(./DocumentFile.svg);
}

.icon-wait {
    mask: url(./Wait.svg);
    -webkit-mask: url(./Wait.svg);
}

.icon-save {
    mask: url(./Save.svg);
    -webkit-mask: url(./Save.svg);
}

.icon-workout {
    mask: url(./Workout.svg);
    -webkit-mask: url(./Workout.svg);
}

.icon-cost-dollar {
    mask: url(./Cost-Dollar.svg);
    -webkit-mask: url(./Cost-Dollar.svg);
}

.icon-zoomin {
    mask: url(./ZoomIn.svg);
    -webkit-mask: url(./ZoomIn.svg);
}

.icon-accountalert {
    mask: url(./AccountAlert.svg);
    -webkit-mask: url(./AccountAlert.svg);
}

.icon-sortascent {
    mask: url(./SortAscent.svg);
    -webkit-mask: url(./SortAscent.svg);
}

.icon-circuitboardbox {
    mask: url(./CircuitBoardBox.svg);
    -webkit-mask: url(./CircuitBoardBox.svg);
}

.icon-vennabvertical {
    mask: url(./VennABVertical.svg);
    -webkit-mask: url(./VennABVertical.svg);
}

.icon-newuser {
    mask: url(./NewUser.svg);
    -webkit-mask: url(./NewUser.svg);
}

.icon-box {
    mask: url(./Box.svg);
    -webkit-mask: url(./Box.svg);
}

.icon-networkport {
    mask: url(./NetworkPort.svg);
    -webkit-mask: url(./NetworkPort.svg);
}

.icon-battery-low {
    mask: url(./Battery-Low.svg);
    -webkit-mask: url(./Battery-Low.svg);
}

.icon-physician {
    mask: url(./Physician.svg);
    -webkit-mask: url(./Physician.svg);
}

.icon-play {
    mask: url(./Play.svg);
    -webkit-mask: url(./Play.svg);
}

.icon-cogs {
    mask: url(./Cogs.svg);
    -webkit-mask: url(./Cogs.svg);
}

.icon-carersspace {
    mask: url(./CarersSpace.svg);
    -webkit-mask: url(./CarersSpace.svg);
}

.icon-budget {
    mask: url(./Budget.svg);
    -webkit-mask: url(./Budget.svg);
}

.icon-firehose {
    mask: url(./FireHose.svg);
    -webkit-mask: url(./FireHose.svg);
}

.icon-documentnotpublished {
    mask: url(./DocumentNotPublished.svg);
    -webkit-mask: url(./DocumentNotPublished.svg);
}

.icon-manageresources {
    mask: url(./ManageResources.svg);
    -webkit-mask: url(./ManageResources.svg);
}

.icon-folderopen {
    mask: url(./FolderOpen.svg);
    -webkit-mask: url(./FolderOpen.svg);
}

.icon-askquestion {
    mask: url(./AskQuestion.svg);
    -webkit-mask: url(./AskQuestion.svg);
}

.icon-walkingworkstation {
    mask: url(./WalkingWorkstation.svg);
    -webkit-mask: url(./WalkingWorkstation.svg);
}

.icon-convert {
    mask: url(./Convert.svg);
    -webkit-mask: url(./Convert.svg);
}

.icon-displaypower {
    mask: url(./DisplayPower.svg);
    -webkit-mask: url(./DisplayPower.svg);
}

.icon-camera {
    mask: url(./Camera.svg);
    -webkit-mask: url(./Camera.svg);
}

.icon-printer {
    mask: url(./Printer.svg);
    -webkit-mask: url(./Printer.svg);
}

.icon-cloudalert {
    mask: url(./CloudAlert.svg);
    -webkit-mask: url(./CloudAlert.svg);
}

.icon-circlecheckmark {
    mask: url(./CircleCheckmark.svg);
    -webkit-mask: url(./CircleCheckmark.svg);
}

.icon-workflow {
    mask: url(./Workflow.svg);
    -webkit-mask: url(./Workflow.svg);
}

.icon-truck {
    mask: url(./Truck.svg);
    -webkit-mask: url(./Truck.svg);
}

.icon-shuttlebus {
    mask: url(./Shuttlebus.svg);
    -webkit-mask: url(./Shuttlebus.svg);
}

.icon-shoppingbag {
    mask: url(./ShoppingBag.svg);
    -webkit-mask: url(./ShoppingBag.svg);
}

.icon-documentexport {
    mask: url(./DocumentExport.svg);
    -webkit-mask: url(./DocumentExport.svg);
}

.icon-circlepercent {
    mask: url(./CirclePercent.svg);
    -webkit-mask: url(./CirclePercent.svg);
}

.icon-centermap {
    mask: url(./CenterMap.svg);
    -webkit-mask: url(./CenterMap.svg);
}

.icon-attachment {
    mask: url(./Attachment.svg);
    -webkit-mask: url(./Attachment.svg);
}

.icon-searchnotfound {
    mask: url(./SearchNotFound.svg);
    -webkit-mask: url(./SearchNotFound.svg);
}

.icon-firstaid {
    mask: url(./FirstAid.svg);
    -webkit-mask: url(./FirstAid.svg);
}

.icon-accept {
    mask: url(./Accept.svg);
    -webkit-mask: url(./Accept.svg);
}

.icon-contacts {
    mask: url(./Contacts.svg);
    -webkit-mask: url(./Contacts.svg);
}

.icon-videoconference {
    mask: url(./VideoConference.svg);
    -webkit-mask: url(./VideoConference.svg);
}

.icon-star {
    mask: url(./Star.svg);
    -webkit-mask: url(./Star.svg);
}

.icon-extension {
    mask: url(./Extension.svg);
    -webkit-mask: url(./Extension.svg);
}

.icon-surveycheckmark {
    mask: url(./SurveyCheckmark.svg);
    -webkit-mask: url(./SurveyCheckmark.svg);
}

.icon-oiltank {
    mask: url(./OilTank.svg);
    -webkit-mask: url(./OilTank.svg);
}

.icon-serenityspace {
    mask: url(./SerenitySpace.svg);
    -webkit-mask: url(./SerenitySpace.svg);
}

.icon-edit {
    mask: url(./Edit.svg);
    -webkit-mask: url(./Edit.svg);
}

.icon-tanker {
    mask: url(./Tanker.svg);
    -webkit-mask: url(./Tanker.svg);
}

.icon-3by3grid {
    mask: url(./3by3Grid.svg);
    -webkit-mask: url(./3by3Grid.svg);
}

.icon-pinboard {
    mask: url(./Pinboard.svg);
    -webkit-mask: url(./Pinboard.svg);
}

.icon-checkbox {
    mask: url(./Checkbox.svg);
    -webkit-mask: url(./Checkbox.svg);
}

.icon-webcaststudio {
    mask: url(./WebcastStudio.svg);
    -webkit-mask: url(./WebcastStudio.svg);
}

.icon-foodbev {
    mask: url(./FoodBev.svg);
    -webkit-mask: url(./FoodBev.svg);
}

.icon-query {
    mask: url(./Query.svg);
    -webkit-mask: url(./Query.svg);
}

.icon-meetingroom {
    mask: url(./MeetingRoom.svg);
    -webkit-mask: url(./MeetingRoom.svg);
}

.icon-education {
    mask: url(./Education.svg);
    -webkit-mask: url(./Education.svg);
}

.icon-orgchart {
    mask: url(./OrgChart.svg);
    -webkit-mask: url(./OrgChart.svg);
}

.icon-lockclosed-locked {
    mask: url(./LockClosed-Locked.svg);
    -webkit-mask: url(./LockClosed-Locked.svg);
}

.icon-spinner {
    mask: url(./Spinner.svg);
    -webkit-mask: url(./Spinner.svg);
}

.icon-pdf {
    mask: url(./PDF.svg);
    -webkit-mask: url(./PDF.svg);
}

.icon-oilplatform {
    mask: url(./OilPlatform.svg);
    -webkit-mask: url(./OilPlatform.svg);
}

.icon-compass {
    mask: url(./Compass.svg);
    -webkit-mask: url(./Compass.svg);
}

.icon-ppt {
    mask: url(./PPT.svg);
    -webkit-mask: url(./PPT.svg);
}

.icon-gameroom {
    mask: url(./GameRoom.svg);
    -webkit-mask: url(./GameRoom.svg);
}

.icon-woman {
    mask: url(./Woman.svg);
    -webkit-mask: url(./Woman.svg);
}

.icon-folderdownload {
    mask: url(./FolderDownload.svg);
    -webkit-mask: url(./FolderDownload.svg);
}

.icon-webcast {
    mask: url(./Webcast.svg);
    -webkit-mask: url(./Webcast.svg);
}

.icon-pencil {
    mask: url(./Pencil.svg);
    -webkit-mask: url(./Pencil.svg);
}

.icon-manandwoman {
    mask: url(./ManAndWoman.svg);
    -webkit-mask: url(./ManAndWoman.svg);
}

.icon-morehorizontal {
    mask: url(./MoreHorizontal.svg);
    -webkit-mask: url(./MoreHorizontal.svg);
}

.icon-folderadd {
    mask: url(./FolderAdd.svg);
    -webkit-mask: url(./FolderAdd.svg);
}

.icon-entrepreneur {
    mask: url(./Entrepreneur.svg);
    -webkit-mask: url(./Entrepreneur.svg);
}

.icon-dial-medium {
    mask: url(./Dial-Medium.svg);
    -webkit-mask: url(./Dial-Medium.svg);
}

.icon-commentary {
    mask: url(./Commentary.svg);
    -webkit-mask: url(./Commentary.svg);
}

.icon-listformat {
    mask: url(./ListFormat.svg);
    -webkit-mask: url(./ListFormat.svg);
}

.icon-summaryprioritisation {
    mask: url(./SummaryPrioritisation.svg);
    -webkit-mask: url(./SummaryPrioritisation.svg);
}

.icon-particulates {
    mask: url(./Particulates.svg);
    -webkit-mask: url(./Particulates.svg);
}

.icon-exit {
    mask: url(./Exit.svg);
    -webkit-mask: url(./Exit.svg);
}

.icon-delete {
    mask: url(./Delete.svg);
    -webkit-mask: url(./Delete.svg);
}

.icon-boxopen {
    mask: url(./BoxOpen.svg);
    -webkit-mask: url(./BoxOpen.svg);
}

.icon-bridgechart {
    mask: url(./BridgeChart.svg);
    -webkit-mask: url(./BridgeChart.svg);
}

.icon-viewoff {
    mask: url(./ViewOff.svg);
    -webkit-mask: url(./ViewOff.svg);
}

.icon-listplay {
    mask: url(./ListPlay.svg);
    -webkit-mask: url(./ListPlay.svg);
}

.icon-call {
    mask: url(./Call.svg);
    -webkit-mask: url(./Call.svg);
}

.icon-informationletter {
    mask: url(./InformationLetter.svg);
    -webkit-mask: url(./InformationLetter.svg);
}

.icon-powerbutton {
    mask: url(./PowerButton.svg);
    -webkit-mask: url(./PowerButton.svg);
}

.icon-dooropen {
    mask: url(./DoorOpen.svg);
    -webkit-mask: url(./DoorOpen.svg);
}

.icon-location {
    mask: url(./Location.svg);
    -webkit-mask: url(./Location.svg);
}

.icon-mortgage {
    mask: url(./Mortgage.svg);
    -webkit-mask: url(./Mortgage.svg);
}

.icon-menuview {
    mask: url(./MenuView.svg);
    -webkit-mask: url(./MenuView.svg);
}

.icon-cloudcheck {
    mask: url(./CloudCheck.svg);
    -webkit-mask: url(./CloudCheck.svg);
}

.icon-documentalert {
    mask: url(./DocumentAlert.svg);
    -webkit-mask: url(./DocumentAlert.svg);
}

.icon-chevrondoubleleft {
    mask: url(./ChevronDoubleLeft.svg);
    -webkit-mask: url(./ChevronDoubleLeft.svg);
}

.icon-share {
    mask: url(./Share.svg);
    -webkit-mask: url(./Share.svg);
}

.icon-documentmultiple {
    mask: url(./DocumentMultiple.svg);
    -webkit-mask: url(./DocumentMultiple.svg);
}

.icon-powerbireport {
    mask: url(./PowerBiReport.svg);
    -webkit-mask: url(./PowerBiReport.svg);
}

.icon-productivity {
    mask: url(./Productivity.svg);
    -webkit-mask: url(./Productivity.svg);
}

.icon-view {
    mask: url(./View.svg);
    -webkit-mask: url(./View.svg);
}

.icon-signoff {
    mask: url(./SignOff.svg);
    -webkit-mask: url(./SignOff.svg);
}

.icon-treeview {
    mask: url(./TreeVIew.svg);
    -webkit-mask: url(./TreeVIew.svg);
}

.icon-growth {
    mask: url(./Growth.svg);
    -webkit-mask: url(./Growth.svg);
}

.icon-video {
    mask: url(./Video.svg);
    -webkit-mask: url(./Video.svg);
}

.icon-filter {
    mask: url(./Filter.svg);
    -webkit-mask: url(./Filter.svg);
}

.icon-shoppingbasket {
    mask: url(./ShoppingBasket.svg);
    -webkit-mask: url(./ShoppingBasket.svg);
}

.icon-circleminus {
    mask: url(./CircleMinus.svg);
    -webkit-mask: url(./CircleMinus.svg);
}

.icon-sparkline {
    mask: url(./SparkLine.svg);
    -webkit-mask: url(./SparkLine.svg);
}

.icon-manufacturer {
    mask: url(./Manufacturer.svg);
    -webkit-mask: url(./Manufacturer.svg);
}

.icon-footer {
    mask: url(./Footer.svg);
    -webkit-mask: url(./Footer.svg);
}

.icon-marketing {
    mask: url(./Marketing.svg);
    -webkit-mask: url(./Marketing.svg);
}

.icon-comment {
    mask: url(./Comment.svg);
    -webkit-mask: url(./Comment.svg);
}

.icon-calendar {
    mask: url(./Calendar.svg);
    -webkit-mask: url(./Calendar.svg);
}

.icon-globe {
    mask: url(./Globe.svg);
    -webkit-mask: url(./Globe.svg);
}

.icon-purpose {
    mask: url(./Purpose.svg);
    -webkit-mask: url(./Purpose.svg);
}

.icon-chevronleftcircle {
    mask: url(./ChevronLeftCircle.svg);
    -webkit-mask: url(./ChevronLeftCircle.svg);
}

.icon-arrowup {
    mask: url(./ArrowUp.svg);
    -webkit-mask: url(./ArrowUp.svg);
}

.icon-data {
    mask: url(./Data.svg);
    -webkit-mask: url(./Data.svg);
}

.icon-accountcheck {
    mask: url(./AccountCheck.svg);
    -webkit-mask: url(./AccountCheck.svg);
}

.icon-creditcardpayment {
    mask: url(./CreditCardPayment.svg);
    -webkit-mask: url(./CreditCardPayment.svg);
}

.icon-chevronup {
    mask: url(./ChevronUp.svg);
    -webkit-mask: url(./ChevronUp.svg);
}

.icon-doorclosed {
    mask: url(./DoorClosed.svg);
    -webkit-mask: url(./DoorClosed.svg);
}

.icon-volumeoff {
    mask: url(./VolumeOff.svg);
    -webkit-mask: url(./VolumeOff.svg);
}

.icon-currencyexchange {
    mask: url(./CurrencyExchange.svg);
    -webkit-mask: url(./CurrencyExchange.svg);
}

.icon-shieldcheck {
    mask: url(./ShieldCheck.svg);
    -webkit-mask: url(./ShieldCheck.svg);
}

.icon-notifications {
    mask: url(./Notifications.svg);
    -webkit-mask: url(./Notifications.svg);
}

.icon-cloud {
    mask: url(./Cloud.svg);
    -webkit-mask: url(./Cloud.svg);
}

.icon-volumedown {
    mask: url(./VolumeDown.svg);
    -webkit-mask: url(./VolumeDown.svg);
}

.icon-office {
    mask: url(./Office.svg);
    -webkit-mask: url(./Office.svg);
}

.icon-confirmed {
    mask: url(./Confirmed.svg);
    -webkit-mask: url(./Confirmed.svg);
}

.icon-upload {
    mask: url(./Upload.svg);
    -webkit-mask: url(./Upload.svg);
}

.icon-arrowleft {
    mask: url(./ArrowLeft.svg);
    -webkit-mask: url(./ArrowLeft.svg);
}

.icon-pause {
    mask: url(./Pause.svg);
    -webkit-mask: url(./Pause.svg);
}

.icon-volumeup {
    mask: url(./VolumeUp.svg);
    -webkit-mask: url(./VolumeUp.svg);
}

.icon-filterremove {
    mask: url(./FilterRemove.svg);
    -webkit-mask: url(./FilterRemove.svg);
}

.icon-newsreport {
    mask: url(./NewsReport.svg);
    -webkit-mask: url(./NewsReport.svg);
}

.icon-chevronleft {
    mask: url(./ChevronLeft.svg);
    -webkit-mask: url(./ChevronLeft.svg);
}

.icon-shieldhalf {
    mask: url(./ShieldHalf.svg);
    -webkit-mask: url(./ShieldHalf.svg);
}

.icon-reject {
    mask: url(./Reject.svg);
    -webkit-mask: url(./Reject.svg);
}

.icon-databasesearch {
    mask: url(./DatabaseSearch.svg);
    -webkit-mask: url(./DatabaseSearch.svg);
}

.icon-bookmark {
    mask: url(./Bookmark.svg);
    -webkit-mask: url(./Bookmark.svg);
}

.icon-zoomout {
    mask: url(./ZoomOut.svg);
    -webkit-mask: url(./ZoomOut.svg);
}

.icon-palmtree {
    mask: url(./PalmTree.svg);
    -webkit-mask: url(./PalmTree.svg);
}

.icon-tablet {
    mask: url(./Tablet.svg);
    -webkit-mask: url(./Tablet.svg);
}

.icon-arrowupbold {
    mask: url(./ArrowUpBold.svg);
    -webkit-mask: url(./ArrowUpBold.svg);
}

.icon-elevator {
    mask: url(./Elevator.svg);
    -webkit-mask: url(./Elevator.svg);
}

.icon-octagram {
    mask: url(./Octagram.svg);
    -webkit-mask: url(./Octagram.svg);
}

.icon-screenmirroring {
    mask: url(./ScreenMirroring.svg);
    -webkit-mask: url(./ScreenMirroring.svg);
}

.icon-chevrondowncircle {
    mask: url(./ChevronDownCircle.svg);
    -webkit-mask: url(./ChevronDownCircle.svg);
}

.icon-noteplus {
    mask: url(./NotePlus.svg);
    -webkit-mask: url(./NotePlus.svg);
}

.icon-trendup {
    mask: url(./TrendUp.svg);
    -webkit-mask: url(./TrendUp.svg);
}

.icon-telecom {
    mask: url(./Telecom.svg);
    -webkit-mask: url(./Telecom.svg);
}

.icon-menu {
    mask: url(./Menu.svg);
    -webkit-mask: url(./Menu.svg);
}

.icon-pushpin {
    mask: url(./Pushpin.svg);
    -webkit-mask: url(./Pushpin.svg);
}

.icon-brightness {
    mask: url(./Brightness.svg);
    -webkit-mask: url(./Brightness.svg);
}

.icon-poll {
    mask: url(./Poll.svg);
    -webkit-mask: url(./Poll.svg);
}

.icon-openinnewwindow {
    mask: url(./OpenInNewWindow.svg);
    -webkit-mask: url(./OpenInNewWindow.svg);
}

.icon-header {
    mask: url(./Header.svg);
    -webkit-mask: url(./Header.svg);
}

.icon-xls {
    mask: url(./XLS.svg);
    -webkit-mask: url(./XLS.svg);
}

.icon-calendardeclined {
    mask: url(./CalendarDeclined.svg);
    -webkit-mask: url(./CalendarDeclined.svg);
}

.icon-airplane {
    mask: url(./Airplane.svg);
    -webkit-mask: url(./Airplane.svg);
}

.icon-calculator {
    mask: url(./Calculator.svg);
    -webkit-mask: url(./Calculator.svg);
}

.icon-object {
    mask: url(./Object.svg);
    -webkit-mask: url(./Object.svg);
}

.icon-revenue {
    mask: url(./Revenue.svg);
    -webkit-mask: url(./Revenue.svg);
}

.icon-thumbsdown2 {
    mask: url(./ThumbsDown2.svg);
    -webkit-mask: url(./ThumbsDown2.svg);
}

.icon-dial-low {
    mask: url(./Dial-Low.svg);
    -webkit-mask: url(./Dial-Low.svg);
}

.icon-man {
    mask: url(./Man.svg);
    -webkit-mask: url(./Man.svg);
}

.icon-building {
    mask: url(./Building.svg);
    -webkit-mask: url(./Building.svg);
}

.icon-avatar {
    mask: url(./Avatar.svg);
    -webkit-mask: url(./Avatar.svg);
}

.icon-endprocess {
    mask: url(./EndProcess.svg);
    -webkit-mask: url(./EndProcess.svg);
}

.icon-folder {
    mask: url(./Folder.svg);
    -webkit-mask: url(./Folder.svg);
}

.icon-camera-mic-speakers {
    mask: url(./Camera-Mic-Speakers.svg);
    -webkit-mask: url(./Camera-Mic-Speakers.svg);
}

.icon-documentlink {
    mask: url(./DocumentLink.svg);
    -webkit-mask: url(./DocumentLink.svg);
}

.icon-minus {
    mask: url(./Minus.svg);
    -webkit-mask: url(./Minus.svg);
}

.icon-ganttchar {
    mask: url(./GanttChar.svg);
    -webkit-mask: url(./GanttChar.svg);
}

.icon-lockoff {
    mask: url(./LockOff.svg);
    -webkit-mask: url(./LockOff.svg);
}

.icon-vennabcreversed {
    mask: url(./VennABCReversed.svg);
    -webkit-mask: url(./VennABCReversed.svg);
}

.icon-exclamation {
    mask: url(./Exclamation.svg);
    -webkit-mask: url(./Exclamation.svg);
}

.icon-weight {
    mask: url(./Weight.svg);
    -webkit-mask: url(./Weight.svg);
}

.icon-accessibility {
    mask: url(./Accessibility.svg);
    -webkit-mask: url(./Accessibility.svg);
}

.icon-layout {
    mask: url(./Layout.svg);
    -webkit-mask: url(./Layout.svg);
}

.icon-accountconvert {
    mask: url(./AccountConvert.svg);
    -webkit-mask: url(./AccountConvert.svg);
}

.icon-notebook {
    mask: url(./Notebook.svg);
    -webkit-mask: url(./Notebook.svg);
}

.icon-powerport-plug-outlet {
    mask: url(./Powerport-Plug-Outlet.svg);
    -webkit-mask: url(./Powerport-Plug-Outlet.svg);
}

.icon-heart {
    mask: url(./Heart.svg);
    -webkit-mask: url(./Heart.svg);
}
