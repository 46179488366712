.postallocation-panel-wrapper {
    .coachees-table-container {
        display: flex;
        width: fit-content;
        margin-top: 30px;
        background-color: #d1d1d1;
    }

    .coachees-checkboxes-component {
        text-align: right;
        margin-right: 0.75rem;
        margin-top: -50px;
    }

    .ap-table-checkable-coachees {
        border-collapse: collapse;
        overflow-y: auto;
        max-height: 800px;
        width: 100%;
        background-color: #d1d1d1;
        box-shadow: none;
        border-radius: unset;
    }

    .ap-table-checkable-coachees {
        th {
            background-color: #d1d1d1;
        }

        tbody {
            tr {
                background-color: white;
            }

            td {
                border-bottom: 1.5px solid #dddddd;
            }
        }

        td:nth-child(2),
        td:nth-child(3),
        td:nth-child(4),
        td:nth-child(5),
        td:nth-child(6),
        td:nth-child(7),
        td:nth-child(8) {
            max-width: 1rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        td:nth-child(1),
        td:nth-child(2) {
            font-weight: bold;
        }

        td:nth-child(8) {
            max-width: 0.75rem;
        }

        td:nth-child(9) {
            border-left: 1.5px solid #dddddd;
            max-width: 8rem;
            min-width: 6rem;
        }
    }

    .header-container {
        height: unset !important;
    }

    .icon-circle-warning-outline {
        color: '#D04A02';
    }

    .tooltip-content {
        word-break: 'break-word';
        overflow-wrap: 'break-word';
        white-space: 'normal';
    }

    .ap-table table thead th .header-container .sort-icon-wrapper {
        width: unset;
    }
}
