.table-container-history {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
}

.allocation-history-table {
    width: 98%;
    background-color: #f3f3f3;
    box-shadow: unset;

    tbody {
        tr {
            background-color: white;
        }
    }

    td:nth-child(1) {
        max-width: 15rem;
    }

    td:nth-child(2) {
        max-width: 15rem;
    }

    td:nth-child(3),
    td:nth-child(4),
    td:nth-child(5) {
        max-width: 8rem;
    }

    td:nth-child(6) {
        border-left: 2px solid #e8e8e8;
        max-width: 10rem;
    }
}

.full {
    overflow-y: scroll;
    max-height: 45vh;
}

.short {
    overflow-y: scroll;
    max-height: 25vh;
}

.ap-panel-body {
    padding: 0.1rem 0.1rem 1.5rem 0.5rem;
}

.history-panel-wrapper {
    background-color: #f3f3f3;
    width: 98%;
    border: 1.5px solid #dadada;
}