@import '@appkit4/styles/appkit.min.css';
@import '@appkit4/react-components/dist/styles/appkit4-react.min.css';
@import './variables';
@import 'bootstrap/dist/css/bootstrap.min.css';

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--color-text-heading);
}

a {
    color: var(--color-text-link-heading);

    &:hover {
        color: var(--color-text-link-heading);
    }
}

body {
    color: var(--color-text-body);
    font-family: 'PwC Helvetica Neue', sans-serif;
    background-image: unset;
}

.no-scroll {
    overflow: hidden;
    height: 100%;
}

.ap-notifications-topRight {
    top: 4rem;
    z-index: 1;
}

.ap-table table tbody tr td {
    vertical-align: middle;
}

.ap-table table tbody tr td span {
    vertical-align: unset;
}

.ap-table table tbody tr td button span {
    margin-right: unset !important;
}

.overflow-visible .ap-modal-body {
    overflow: visible;
}

// required to disable white text on dropdown in dark mode
// (not default appkit behaviour but when buttons are made properly accessible
// then this bug is introduced)
.dropdown-button {
    color: var(--color-text-body);
    background: none;
}

// Additional margin to prevent the focus border from being cropped across the top
.focus-margin {
    margin-top: 5px;
}

#groupdropdown {
    z-index: 2;
}

[data-mode='dark'],
[data-mode='dark'] ::before,
[data-mode='dark'] ::after {
    --level-3: 0 0.5rem 1rem -0.125rem rgba(var(--color-background-border-rgb), var(--opacity-4)) !important;
    --box-shadow-3: var(--level-3) !important;
}

[data-mode='light'],
[data-mode='light'] ::before,
[data-mode='light'] ::after {
    --color-background-default: #ffffff !important;
    --color-background-default-rgb: 255, 255, 255 !important;
    --color-background-container-alt: #ffffff !important;
    --color-background-container-alt-rgb: 255, 255, 255 !important;
}
