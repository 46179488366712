.page-container {
    max-width: 1096;
    margin-bottom: 0.5rem;
}

.content-container {
    display: flex;
    flex: 1;
    flex-direction: row;
    margin-bottom: 0.5rem;
}
