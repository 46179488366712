.preallocation-panel-wrapper {
    .coaches-table-container {
        width: 100%;
        display: flex;
        margin-top: 30px;
    }

    .coaches-checkboxes-component {
        display: flex;
        margin-left: 87%;
        margin-top: -50px;
    }

    .ap-table-checkable-coaches {
        width: 135%;
        max-height: 550px;
        overflow-y: auto;
        background-color: #f3f3f3;
        border-collapse: collapse;
        box-shadow: none;
    }

    .ap-table-checkable-coaches {
        th {
            background-color: #f3f3f3;
        }
        tbody {
            tr {
                background-color: white;
            }
            tr:not(:last-child) {
                border-bottom: 1.5px solid #dddddd;
            }
        }
        td:nth-child(8) {
            border-left: 1.5px solid #dddddd;
            max-width: 12rem;
            min-width: 10rem;
        }
    }

    td:nth-child(3),
    td:nth-child(5),
    td:nth-child(6) {
        max-width: 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    td:nth-child(4) {
        max-width: 10rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .adjust-coach-capacity-button {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 1rem;
    }

    .adjust-coach-capacity-button-component {
        background-color: white !important;
    }

    .header-container {
        height: unset !important;
    }

    .ap-table table thead th .header-container .sort-icon-wrapper {
        width: unset;
    }
}
