.allocations-empty-container {
    display: flex;
    align-items: center;
}

.allocations-empty-panels {
    width: 98%;
    margin-top: 3%;
    margin-bottom: 3%;
    background-color: #f3f3f3;
}

.allocations-empty-panel-text {
    text-align: center;
    font-size: 20px;
}

.allocations-empty-button {
    display: flex;
    margin-left: 40%;
}
