@import '../../styles/variables';
@import '@appkit4/styles/appkit.min.css';
@import '@appkit4/styles/scss/variables';

$calendar-empty-color: $color-background-container;

.business-metrics-user-records-table-container {
    overflow-x: auto;
    border: 1px solid var(--color-background-border);
    box-shadow: none;
    table {
        white-space: nowrap;
    }
}

.ap-container {
    padding: 0px;
    width: 100%;
}

h3 {
    padding-top: 5px;
    margin: 0px;
}

h4 {
    font-size: 15px;
    padding-top: 15px;
}

.responsive-pie {
    height: 150px;
}

.row .charts {
    margin-bottom: -130px;
}

.panel-container {
    background-color: $color-background-container;
    padding: 10px 0px 10px 10px;
}

.row > .col-md-4 {
    padding-left: 10px;
    padding-right: 0px;
    margin-bottom: 10px;
}

.row > .col-md-12 {
    padding-right: 1px;
    padding-left: 10px;
}

.responsive-calendar {
    height: 200px;
}

.search-bar {
    padding-top: 10px;
}

.no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 12px;
}
