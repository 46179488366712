.upload-form-container {
    margin-top: -4rem;
    padding-top: 50px;
    margin-left: 3%;
}

.brand-text-title-new-allocation {
    margin-left: 3%;
}

.ap-fileupload {
    width: 90.5%;
    margin-left: 3%;
}

.next-button-new-allocation {
    margin-right: 1.5rem;
    margin-top: 2rem;
    float: right;
}

.grayed-out {
    filter: grayscale(100%);
    opacity: 0.35;
}

.no-recent-uploads {
    width: 88%;
    margin: auto;
    text-align: center;
}

.no-recent-uploads-panel {
    background-color: #f3f3f3;
    align-items: center;
    padding-top: 1rem;
    margin-top: 1.5rem;
}

.upload-description {
    margin-left: 3%;
    margin-right: 5%;
}

.newallocation-panel-wrapper {
    background-color: #f3f3f3;
    width: 88%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2rem;
    border: 1.5px solid #dadada;

    .checkboxes-component {
        display: flex;
        margin-left: 87%;
        margin-top: -50px;
    }

    .ap-table-checkable {
        overflow-y: auto;
        max-height: 23vh;
        width: 100%;
        box-shadow: none;
        background-color: #f3f3f3;

        th {
            background-color: #f3f3f3;
        }
        tbody {
            tr {
                background-color: white;
            }
        }
    }

    .ap-panel-body {
        padding: 0.1rem 0.1rem 1.5rem 0.5rem;
    }
}
