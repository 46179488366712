.global-pagination-container {
    float: right;

    .ap-pagination .ap-pagination-field .ap-field .ap-field-wrapper input {
        text-align: center;
    }

    .global-pagination-dropdown {
        display: flex;

        .ap-field .ap-field-container .ap-field-wrapper input {
            margin: unset;
            margin-left: 10px;
            padding: unset;
            width: 50px;
        }

        .ap-field.has-length .ap-field-title-container {
            display: none;
        }

        .ap-dropdown .ap-dropdown-select .icon-down-chevron-outline {
            margin-right: 3px;
            margin-top: unset;
        }

        .ap-field .ap-field-title-container {
            top: unset;
            color: black;
        }

        .ap-field .ap-field-container .ap-field-wrapper {
            height: 1.5rem;
        }

        .ap-field.has-length .ap-field-title-container {
            line-height: unset;
            font-size: unset;
        }

        .ap-dropdown
            .ap-dropdown-select
            .ap-field
            .ap-field-container
            .ap-field-wrapper
            .ap-field-input {
            padding-right: unset !important;
        }

        .global-pagination-component {
            display: table-cell;
        }
    }
}
