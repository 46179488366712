.preallocation-panel-wrapper {
    .coachees-table-container {
        display: flex;
        margin-top: 30px;
        background-color: #f3f3f3;
    }

    .coachees-checkboxes-component {
        display: flex;
        margin-left: 87%;
        margin-top: -50px;
    }

    .ap-table-checkable-coachees {
        border-collapse: collapse;
        overflow-y: auto;
        max-height: 550px;
        width: 100%;
        background-color: #f3f3f3;
        box-shadow: none;
    }

    .ap-table-checkable-coachees {
        th {
            background-color: #f3f3f3;
        }
        tbody {
            tr {
                background-color: white;
            }
            tr:not(:last-child) {
                border-bottom: 1.5px solid #dddddd;
            }
        }

        td:nth-child(8) {
            border-left: 1.5px solid #dddddd;
            max-width: 12rem;
            min-width: 10rem;
        }
    }

    .header-container {
        height: unset !important;
    }

    .ap-table table thead th .header-container .sort-icon-wrapper {
        width: unset;
    }
}
