.postallocation-page-wrapper {
    .postallocation-tabs {
        margin-bottom: 32px;
    }

    .search-sample {
        display: flex;
    }

    .search-bar {
        width: 320px;
    }

    .filter-pills {
        margin-top: 25px;
        margin-left: 16px;
        gap: 10px;
        display: flex;
        height: 19px;
    }

    .filter-pills:hover {
        cursor: pointer;
    }

    .postallocation-pill-one.clicked {
        background-color: #a62b1e;
    }

    .postallocation-pill-two.clicked {
        background-color: #a62b1e;
    }

    .pill-disabled {
        background-color: #7d7d7d;
        cursor: not-allowed;
    }

    .postallocation-panel-wrapper {
        width: 99%;
        background-color: #d1d1d1;
    }

    .postallocation-button-wrapper {
        display: inline-block;
        width: 100%;
        margin-top: 1rem;
    }

    .postallocation-left-button-component {
        float: left;
    }

    .postallocation-right-button-component {
        float: right;
        margin-right: 15px;
    }

    .hidden {
        display: none;
    }

    .ap-panel {
        border: 1px solid #e8e8e8;
    }

    .header-container.has-sorter:hover,
    .header-container.head-sorted {
        background-color: unset !important;
    }

    .ap-table table {
        border-spacing: unset;
        border-collapse: unset;
    }

    .table-head-padding {
        margin-bottom: 10px;
    }

    .ap-panel-body {
        padding: 0.5rem;
    }
}
