@import '../../styles/variables';

body.landing {
    background-image: url('../../assets/illustrations/LandingPage.svg');

    @media (max-width: $breakpoint-md) {
        background-size: 80%;
    }

    background-color: var(--color-background-primary);
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: contain;
}

body.landing[data-mode='dark'][data-theme='black'] {
    background-color: #2d2d2d;
}

.title {
    color: white;

	@media (max-width: $breakpoint-md) {
        margin-top: 20%;
    }
}

.logo {
    min-width: 62px;
    object-fit: contain;
    margin-left: 3%;
	max-width: 20%;

    @media (max-width: $breakpoint-md) {
        max-width: 25%;
        max-height: 25%;
        margin-left: 0%;
    }
}

.login-btn {
    min-width: 25%;
    width: fit-content;
    border: 1px solid white !important;
}

.content {
    margin-left: 20vw;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @media (max-width: $breakpoint-md) {
        max-width: 50%;
    }
}
