.admin-table-container {
    overflow-x: auto;
    border: 1px solid var(--color-background-border);
    box-shadow: none;
    table-layout: auto;
    width: 100%;
    td,
    th,
    span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    @media (min-width: 1200px) {
        td,
        th {
            max-width: 200px;
        }
    }
    @media (max-width: 1199px) {
        td,
        th {
            max-width: 150px;
        }
    }
    @media (max-width: 991px) {
        td,
        th {
            max-width: 120px;
        }
    }
    @media (max-width: 767px) {
        td,
        th {
            max-width: 100px;
        }
    }
    @media (max-width: 575px) {
        td {
            max-width: 25px;
            span {
                font-size: 12px;
                height: auto;
            }
        }
        th {
            max-width: 65px;
            span {
                font-size: 12px;
                height: auto;
            }
        }
        .row {
            width: 64px;
            .ps-1 {
                padding-left: 0 !important;
            }
        }
    }
}

.actions-icons {
    --ap-gutter-x: 0rem !important;
    margin: auto !important;
}

.ap-avatar-text {
    overflow: visible !important;
}

@media (max-width: 575px) {
    .header-container {
        height: 2rem !important;
    }
    .ap-table table tr td:last-child {
            padding: 0;
    }
}

textarea.ap-field-input{
    height: 12rem !important;
}