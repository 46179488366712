.pwc-brand-pictogram {
  display: inline-flex;
  width: 24px;
  height: 24px;
  background-color: black;

  &-small {
    @extend .pwc-brand-pictogram;
    width: 16px;
    height: 16px;
  }

  &-large {
    @extend .pwc-brand-pictogram;
    width: 32px;
    height: 32px;
  }

  &-xlarge {
    @extend .pwc-brand-pictogram;
    width: 40px;
    height: 40px;
  }
}

.pictogram-passport {
  mask: url(./Passport.svg);
  -webkit-mask: url(./Passport.svg);
}

.pictogram-chemicals {
  mask: url(./Chemicals.svg);
  -webkit-mask: url(./Chemicals.svg);
}

.pictogram-submarine {
  mask: url(./Submarine.svg);
  -webkit-mask: url(./Submarine.svg);
}

.pictogram-numbers-4 {
  mask: url(./Numbers-4.svg);
  -webkit-mask: url(./Numbers-4.svg);
}

.pictogram-change2arrows {
  mask: url(./Change2Arrows.svg);
  -webkit-mask: url(./Change2Arrows.svg);
}

.pictogram-platforms {
  mask: url(./Platforms.svg);
  -webkit-mask: url(./Platforms.svg);
}

.pictogram-sports {
  mask: url(./Sports.svg);
  -webkit-mask: url(./Sports.svg);
}

.pictogram-payment {
  mask: url(./Payment.svg);
  -webkit-mask: url(./Payment.svg);
}

.pictogram-genetics {
  mask: url(./Genetics.svg);
  -webkit-mask: url(./Genetics.svg);
}

.pictogram-cycle1 {
  mask: url(./Cycle1.svg);
  -webkit-mask: url(./Cycle1.svg);
}

.pictogram-sign {
  mask: url(./Sign.svg);
  -webkit-mask: url(./Sign.svg);
}

.pictogram-aidrivenannotation {
  mask: url(./AIDrivenAnnotation.svg);
  -webkit-mask: url(./AIDrivenAnnotation.svg);
}

.pictogram-eventequipment {
  mask: url(./EventEquipment.svg);
  -webkit-mask: url(./EventEquipment.svg);
}

.pictogram-refinery {
  mask: url(./Refinery.svg);
  -webkit-mask: url(./Refinery.svg);
}

.pictogram-yes {
  mask: url(./Yes.svg);
  -webkit-mask: url(./Yes.svg);
}

.pictogram-sound {
  mask: url(./Sound.svg);
  -webkit-mask: url(./Sound.svg);
}

.pictogram-bestpracticesmedical {
  mask: url(./BestPracticesMedical.svg);
  -webkit-mask: url(./BestPracticesMedical.svg);
}

.pictogram-house {
  mask: url(./House.svg);
  -webkit-mask: url(./House.svg);
}

.pictogram-wind {
  mask: url(./Wind.svg);
  -webkit-mask: url(./Wind.svg);
}

.pictogram-numbers-5 {
  mask: url(./Numbers-5.svg);
  -webkit-mask: url(./Numbers-5.svg);
}

.pictogram-petrol {
  mask: url(./Petrol.svg);
  -webkit-mask: url(./Petrol.svg);
}

.pictogram-target {
  mask: url(./Target.svg);
  -webkit-mask: url(./Target.svg);
}

.pictogram-documentsections {
  mask: url(./DocumentSections.svg);
  -webkit-mask: url(./DocumentSections.svg);
}

.pictogram-drivingefficiency {
  mask: url(./DrivingEfficiency.svg);
  -webkit-mask: url(./DrivingEfficiency.svg);
}

.pictogram-diversity {
  mask: url(./Diversity.svg);
  -webkit-mask: url(./Diversity.svg);
}

.pictogram-lowbattery {
  mask: url(./LowBattery.svg);
  -webkit-mask: url(./LowBattery.svg);
}

.pictogram-information {
  mask: url(./Information.svg);
  -webkit-mask: url(./Information.svg);
}

.pictogram-lockers {
  mask: url(./Lockers.svg);
  -webkit-mask: url(./Lockers.svg);
}

.pictogram-vaccination {
  mask: url(./Vaccination.svg);
  -webkit-mask: url(./Vaccination.svg);
}

.pictogram-datasecurity {
  mask: url(./DataSecurity.svg);
  -webkit-mask: url(./DataSecurity.svg);
}

.pictogram-documentautomation {
  mask: url(./DocumentAutomation.svg);
  -webkit-mask: url(./DocumentAutomation.svg);
}

.pictogram-reset {
  mask: url(./Reset.svg);
  -webkit-mask: url(./Reset.svg);
}

.pictogram-hourglass {
  mask: url(./Hourglass.svg);
  -webkit-mask: url(./Hourglass.svg);
}

.pictogram-qualityofearnings {
  mask: url(./QualityofEarnings.svg);
  -webkit-mask: url(./QualityofEarnings.svg);
}

.pictogram-numbers-20 {
  mask: url(./Numbers-20.svg);
  -webkit-mask: url(./Numbers-20.svg);
}

.pictogram-numbers-7 {
  mask: url(./Numbers-7.svg);
  -webkit-mask: url(./Numbers-7.svg);
}

.pictogram-medicine {
  mask: url(./Medicine.svg);
  -webkit-mask: url(./Medicine.svg);
}

.pictogram-drought {
  mask: url(./Drought.svg);
  -webkit-mask: url(./Drought.svg);
}

.pictogram-endocrinology {
  mask: url(./Endocrinology.svg);
  -webkit-mask: url(./Endocrinology.svg);
}

.pictogram-callcenter {
  mask: url(./CallCenter.svg);
  -webkit-mask: url(./CallCenter.svg);
}

.pictogram-cycle2 {
  mask: url(./Cycle2.svg);
  -webkit-mask: url(./Cycle2.svg);
}

.pictogram-storms-rain {
  mask: url(./Storms-Rain.svg);
  -webkit-mask: url(./Storms-Rain.svg);
}

.pictogram-children {
  mask: url(./Children.svg);
  -webkit-mask: url(./Children.svg);
}

.pictogram-militarydrone {
  mask: url(./MilitaryDrone.svg);
  -webkit-mask: url(./MilitaryDrone.svg);
}

.pictogram-polyclinic-outpatient {
  mask: url(./Polyclinic-Outpatient.svg);
  -webkit-mask: url(./Polyclinic-Outpatient.svg);
}

.pictogram-approval {
  mask: url(./Approval.svg);
  -webkit-mask: url(./Approval.svg);
}

.pictogram-managedservices {
  mask: url(./ManagedServices.svg);
  -webkit-mask: url(./ManagedServices.svg);
}

.pictogram-cattlefarming {
  mask: url(./CattleFarming.svg);
  -webkit-mask: url(./CattleFarming.svg);
}

.pictogram-geometrymath {
  mask: url(./GeometryMath.svg);
  -webkit-mask: url(./GeometryMath.svg);
}

.pictogram-docx {
  mask: url(./DOCX.svg);
  -webkit-mask: url(./DOCX.svg);
}

.pictogram-agriculture {
  mask: url(./Agriculture.svg);
  -webkit-mask: url(./Agriculture.svg);
}

.pictogram-cycle3 {
  mask: url(./Cycle3.svg);
  -webkit-mask: url(./Cycle3.svg);
}

.pictogram-sustainability {
  mask: url(./Sustainability.svg);
  -webkit-mask: url(./Sustainability.svg);
}

.pictogram-cannabis {
  mask: url(./Cannabis.svg);
  -webkit-mask: url(./Cannabis.svg);
}

.pictogram-naturallanguageprocessing {
  mask: url(./NaturalLanguageProcessing.svg);
  -webkit-mask: url(./NaturalLanguageProcessing.svg);
}

.pictogram-technologymediatelecom {
  mask: url(./TechnologyMediaTelecom.svg);
  -webkit-mask: url(./TechnologyMediaTelecom.svg);
}

.pictogram-numbers-6 {
  mask: url(./Numbers-6.svg);
  -webkit-mask: url(./Numbers-6.svg);
}

.pictogram-travelagency {
  mask: url(./TravelAgency.svg);
  -webkit-mask: url(./TravelAgency.svg);
}

.pictogram-co2 {
  mask: url(./Co2.svg);
  -webkit-mask: url(./Co2.svg);
}

.pictogram-doctor {
  mask: url(./Doctor.svg);
  -webkit-mask: url(./Doctor.svg);
}

.pictogram-healthcare {
  mask: url(./Healthcare.svg);
  -webkit-mask: url(./Healthcare.svg);
}

.pictogram-stormscyclone {
  mask: url(./StormsCyclone.svg);
  -webkit-mask: url(./StormsCyclone.svg);
}

.pictogram-smartphone {
  mask: url(./Smartphone.svg);
  -webkit-mask: url(./Smartphone.svg);
}

.pictogram-television {
  mask: url(./Television.svg);
  -webkit-mask: url(./Television.svg);
}

.pictogram-artificialintelligence {
  mask: url(./ArtificialIntelligence.svg);
  -webkit-mask: url(./ArtificialIntelligence.svg);
}

.pictogram-transferpricing {
  mask: url(./TransferPricing.svg);
  -webkit-mask: url(./TransferPricing.svg);
}

.pictogram-numbers-19 {
  mask: url(./Numbers-19.svg);
  -webkit-mask: url(./Numbers-19.svg);
}

.pictogram-recycle {
  mask: url(./Recycle.svg);
  -webkit-mask: url(./Recycle.svg);
}

.pictogram-surveillance {
  mask: url(./Surveillance.svg);
  -webkit-mask: url(./Surveillance.svg);
}

.pictogram-numbers-2 {
  mask: url(./Numbers-2.svg);
  -webkit-mask: url(./Numbers-2.svg);
}

.pictogram-highfive {
  mask: url(./HighFive.svg);
  -webkit-mask: url(./HighFive.svg);
}

.pictogram-socialdistancing {
  mask: url(./SocialDistancing.svg);
  -webkit-mask: url(./SocialDistancing.svg);
}

.pictogram-pulmonology {
  mask: url(./Pulmonology.svg);
  -webkit-mask: url(./Pulmonology.svg);
}

.pictogram-fruitsvegetables {
  mask: url(./FruitsVegetables.svg);
  -webkit-mask: url(./FruitsVegetables.svg);
}

.pictogram-swotanalysis {
  mask: url(./SWOTAnalysis.svg);
  -webkit-mask: url(./SWOTAnalysis.svg);
}

.pictogram-diningfood {
  mask: url(./DiningFood.svg);
  -webkit-mask: url(./DiningFood.svg);
}

.pictogram-weathercold {
  mask: url(./WeatherCold.svg);
  -webkit-mask: url(./WeatherCold.svg);
}

.pictogram-regulation {
  mask: url(./Regulation.svg);
  -webkit-mask: url(./Regulation.svg);
}

.pictogram-agreementdeal {
  mask: url(./AgreementDeal.svg);
  -webkit-mask: url(./AgreementDeal.svg);
}

.pictogram-shipswheel {
  mask: url(./ShipsWheel.svg);
  -webkit-mask: url(./ShipsWheel.svg);
}

.pictogram-brandedpromoitems {
  mask: url(./BrandedPromoItems.svg);
  -webkit-mask: url(./BrandedPromoItems.svg);
}

.pictogram-forest {
  mask: url(./Forest.svg);
  -webkit-mask: url(./Forest.svg);
}

.pictogram-retail {
  mask: url(./Retail.svg);
  -webkit-mask: url(./Retail.svg);
}

.pictogram-playvideo {
  mask: url(./Playvideo.svg);
  -webkit-mask: url(./Playvideo.svg);
}

.pictogram-fullbattery {
  mask: url(./FullBattery.svg);
  -webkit-mask: url(./FullBattery.svg);
}

.pictogram-writingdocument {
  mask: url(./WritingDocument.svg);
  -webkit-mask: url(./WritingDocument.svg);
}

.pictogram-flour {
  mask: url(./Flour.svg);
  -webkit-mask: url(./Flour.svg);
}

.pictogram-ticket {
  mask: url(./Ticket.svg);
  -webkit-mask: url(./Ticket.svg);
}

.pictogram-productportfoliobalance {
  mask: url(./ProductPortfolioBalance.svg);
  -webkit-mask: url(./ProductPortfolioBalance.svg);
}

.pictogram-climatechange {
  mask: url(./ClimateChange.svg);
  -webkit-mask: url(./ClimateChange.svg);
}

.pictogram-transportationrail {
  mask: url(./TransportationRail.svg);
  -webkit-mask: url(./TransportationRail.svg);
}

.pictogram-operatingexpense {
  mask: url(./OperatingExpense.svg);
  -webkit-mask: url(./OperatingExpense.svg);
}

.pictogram-numbers-3 {
  mask: url(./Numbers-3.svg);
  -webkit-mask: url(./Numbers-3.svg);
}

.pictogram-gaming {
  mask: url(./Gaming.svg);
  -webkit-mask: url(./Gaming.svg);
}

.pictogram-militaryjet {
  mask: url(./MilitaryJet.svg);
  -webkit-mask: url(./MilitaryJet.svg);
}

.pictogram-numbers-18 {
  mask: url(./Numbers-18.svg);
  -webkit-mask: url(./Numbers-18.svg);
}

.pictogram-apparelretail {
  mask: url(./ApparelRetail.svg);
  -webkit-mask: url(./ApparelRetail.svg);
}

.pictogram-research {
  mask: url(./Research.svg);
  -webkit-mask: url(./Research.svg);
}

.pictogram-bottles {
  mask: url(./Bottles.svg);
  -webkit-mask: url(./Bottles.svg);
}

.pictogram-presentation {
  mask: url(./Presentation.svg);
  -webkit-mask: url(./Presentation.svg);
}

.pictogram-financialservicesbanking1 {
  mask: url(./FinancialServicesBanking1.svg);
  -webkit-mask: url(./FinancialServicesBanking1.svg);
}

.pictogram-machinelearning {
  mask: url(./MachineLearning.svg);
  -webkit-mask: url(./MachineLearning.svg);
}

.pictogram-giftassortment {
  mask: url(./GiftAssortment.svg);
  -webkit-mask: url(./GiftAssortment.svg);
}

.pictogram-map {
  mask: url(./Map.svg);
  -webkit-mask: url(./Map.svg);
}

.pictogram-community {
  mask: url(./Community.svg);
  -webkit-mask: url(./Community.svg);
}

.pictogram-mailmessage {
  mask: url(./MailMessage.svg);
  -webkit-mask: url(./MailMessage.svg);
}

.pictogram-technologydigital {
  mask: url(./TechnologyDigital.svg);
  -webkit-mask: url(./TechnologyDigital.svg);
}

.pictogram-microphone {
  mask: url(./Microphone.svg);
  -webkit-mask: url(./Microphone.svg);
}

.pictogram-database {
  mask: url(./Database.svg);
  -webkit-mask: url(./Database.svg);
}

.pictogram-physical-bewell {
  mask: url(./Physical-BeWell.svg);
  -webkit-mask: url(./Physical-BeWell.svg);
}

.pictogram-stewardship {
  mask: url(./Stewardship.svg);
  -webkit-mask: url(./Stewardship.svg);
}

.pictogram-toolsplansdesign {
  mask: url(./ToolsPlansDesign.svg);
  -webkit-mask: url(./ToolsPlansDesign.svg);
}

.pictogram-policing {
  mask: url(./Policing.svg);
  -webkit-mask: url(./Policing.svg);
}

.pictogram-anomalydetection {
  mask: url(./AnomalyDetection.svg);
  -webkit-mask: url(./AnomalyDetection.svg);
}

.pictogram-file {
  mask: url(./File.svg);
  -webkit-mask: url(./File.svg);
}

.pictogram-3dprinting {
  mask: url(./3DPrinting.svg);
  -webkit-mask: url(./3DPrinting.svg);
}

.pictogram-capitalexpenditures-yen {
  mask: url(./CapitalExpenditures-Yen.svg);
  -webkit-mask: url(./CapitalExpenditures-Yen.svg);
}

.pictogram-cycle4 {
  mask: url(./Cycle4.svg);
  -webkit-mask: url(./Cycle4.svg);
}

.pictogram-founder-hospital {
  mask: url(./Founder-Hospital.svg);
  -webkit-mask: url(./Founder-Hospital.svg);
}

.pictogram-revenue-yen {
  mask: url(./Revenue-Yen.svg);
  -webkit-mask: url(./Revenue-Yen.svg);
}

.pictogram-brain {
  mask: url(./Brain.svg);
  -webkit-mask: url(./Brain.svg);
}

.pictogram-male {
  mask: url(./Male.svg);
  -webkit-mask: url(./Male.svg);
}

.pictogram-hydraulicenergy {
  mask: url(./HydraulicEnergy.svg);
  -webkit-mask: url(./HydraulicEnergy.svg);
}

.pictogram-tiff {
  mask: url(./TIFF.svg);
  -webkit-mask: url(./TIFF.svg);
}

.pictogram-accesstoinformation {
  mask: url(./AccesstoInformation.svg);
  -webkit-mask: url(./AccesstoInformation.svg);
}

.pictogram-chess {
  mask: url(./Chess.svg);
  -webkit-mask: url(./Chess.svg);
}

.pictogram-devices {
  mask: url(./Devices.svg);
  -webkit-mask: url(./Devices.svg);
}

.pictogram-helpdesk {
  mask: url(./HelpDesk.svg);
  -webkit-mask: url(./HelpDesk.svg);
}

.pictogram-intelligentcheck {
  mask: url(./IntelligentCheck.svg);
  -webkit-mask: url(./IntelligentCheck.svg);
}

.pictogram-medicaldirector {
  mask: url(./MedicalDirector.svg);
  -webkit-mask: url(./MedicalDirector.svg);
}

.pictogram-strategy {
  mask: url(./Strategy.svg);
  -webkit-mask: url(./Strategy.svg);
}

.pictogram-peopleaudienceteamwork {
  mask: url(./PeopleAudienceTeamwork.svg);
  -webkit-mask: url(./PeopleAudienceTeamwork.svg);
}

.pictogram-utilitieselectricity {
  mask: url(./UtilitiesElectricity.svg);
  -webkit-mask: url(./UtilitiesElectricity.svg);
}

.pictogram-tourist {
  mask: url(./Tourist.svg);
  -webkit-mask: url(./Tourist.svg);
}

.pictogram-scale-balance {
  mask: url(./Scale-Balance.svg);
  -webkit-mask: url(./Scale-Balance.svg);
}

.pictogram-accesstomanagement {
  mask: url(./AccesstoManagement.svg);
  -webkit-mask: url(./AccesstoManagement.svg);
}

.pictogram-voiceassistance {
  mask: url(./VoiceAssistance.svg);
  -webkit-mask: url(./VoiceAssistance.svg);
}

.pictogram-energy {
  mask: url(./Energy.svg);
  -webkit-mask: url(./Energy.svg);
}

.pictogram-doctor-surgeon {
  mask: url(./Doctor-Surgeon.svg);
  -webkit-mask: url(./Doctor-Surgeon.svg);
}

.pictogram-organizationalchart {
  mask: url(./OrganizationalChart.svg);
  -webkit-mask: url(./OrganizationalChart.svg);
}

.pictogram-ruble {
  mask: url(./Ruble.svg);
  -webkit-mask: url(./Ruble.svg);
}

.pictogram-financialservicesbanking2 {
  mask: url(./FinancialServicesBanking2.svg);
  -webkit-mask: url(./FinancialServicesBanking2.svg);
}

.pictogram-videocall {
  mask: url(./VideoCall.svg);
  -webkit-mask: url(./VideoCall.svg);
}

.pictogram-movein {
  mask: url(./MoveIn.svg);
  -webkit-mask: url(./MoveIn.svg);
}

.pictogram-stopsign {
  mask: url(./StopSign.svg);
  -webkit-mask: url(./StopSign.svg);
}

.pictogram-simulation {
  mask: url(./Simulation.svg);
  -webkit-mask: url(./Simulation.svg);
}

.pictogram-policingdigital {
  mask: url(./PolicingDigital.svg);
  -webkit-mask: url(./PolicingDigital.svg);
}

.pictogram-question {
  mask: url(./Question.svg);
  -webkit-mask: url(./Question.svg);
}

.pictogram-robotic {
  mask: url(./Robotic.svg);
  -webkit-mask: url(./Robotic.svg);
}

.pictogram-lion {
  mask: url(./Lion.svg);
  -webkit-mask: url(./Lion.svg);
}

.pictogram-consumermarketscreditcard {
  mask: url(./ConsumerMarketsCreditCard.svg);
  -webkit-mask: url(./ConsumerMarketsCreditCard.svg);
}

.pictogram-thumbsup {
  mask: url(./ThumbsUp.svg);
  -webkit-mask: url(./ThumbsUp.svg);
}

.pictogram-breadthofscope {
  mask: url(./BreadthofScope.svg);
  -webkit-mask: url(./BreadthofScope.svg);
}

.pictogram-financialstatement {
  mask: url(./FinancialStatement.svg);
  -webkit-mask: url(./FinancialStatement.svg);
}

.pictogram-powerplant {
  mask: url(./PowerPlant.svg);
  -webkit-mask: url(./PowerPlant.svg);
}

.pictogram-airambulance {
  mask: url(./AirAmbulance.svg);
  -webkit-mask: url(./AirAmbulance.svg);
}

.pictogram-ctscan-mri {
  mask: url(./CTScan-MRI.svg);
  -webkit-mask: url(./CTScan-MRI.svg);
}

.pictogram-textlayout {
  mask: url(./TextLayout.svg);
  -webkit-mask: url(./TextLayout.svg);
}

.pictogram-scanqrcode {
  mask: url(./ScanQRCode.svg);
  -webkit-mask: url(./ScanQRCode.svg);
}

.pictogram-euro {
  mask: url(./Euro.svg);
  -webkit-mask: url(./Euro.svg);
}

.pictogram-key {
  mask: url(./Key.svg);
  -webkit-mask: url(./Key.svg);
}

.pictogram-ebook {
  mask: url(./eBook.svg);
  -webkit-mask: url(./eBook.svg);
}

.pictogram-sustainablefinance {
  mask: url(./SustainableFinance.svg);
  -webkit-mask: url(./SustainableFinance.svg);
}

.pictogram-animals {
  mask: url(./Animals.svg);
  -webkit-mask: url(./Animals.svg);
}

.pictogram-sugar {
  mask: url(./Sugar.svg);
  -webkit-mask: url(./Sugar.svg);
}

.pictogram-documentshredder {
  mask: url(./DocumentShredder.svg);
  -webkit-mask: url(./DocumentShredder.svg);
}

.pictogram-rehabilitation {
  mask: url(./Rehabilitation.svg);
  -webkit-mask: url(./Rehabilitation.svg);
}

.pictogram-ambulance {
  mask: url(./Ambulance.svg);
  -webkit-mask: url(./Ambulance.svg);
}

.pictogram-stopwatch {
  mask: url(./Stopwatch.svg);
  -webkit-mask: url(./Stopwatch.svg);
}

.pictogram-earnosethroat {
  mask: url(./EarNoseThroat.svg);
  -webkit-mask: url(./EarNoseThroat.svg);
}

.pictogram-settings {
  mask: url(./Settings.svg);
  -webkit-mask: url(./Settings.svg);
}

.pictogram-officefurniture {
  mask: url(./OfficeFurniture.svg);
  -webkit-mask: url(./OfficeFurniture.svg);
}

.pictogram-travel {
  mask: url(./Travel.svg);
  -webkit-mask: url(./Travel.svg);
}

.pictogram-hydrogen {
  mask: url(./Hydrogen.svg);
  -webkit-mask: url(./Hydrogen.svg);
}

.pictogram-lowercosts-yen {
  mask: url(./LowerCosts-Yen.svg);
  -webkit-mask: url(./LowerCosts-Yen.svg);
}

.pictogram-ebitda-yen {
  mask: url(./EBITDA-Yen.svg);
  -webkit-mask: url(./EBITDA-Yen.svg);
}

.pictogram-nfttoken {
  mask: url(./NFTToken.svg);
  -webkit-mask: url(./NFTToken.svg);
}

.pictogram-gaspipeline {
  mask: url(./GasPipeline.svg);
  -webkit-mask: url(./GasPipeline.svg);
}

.pictogram-pharmaceuticals {
  mask: url(./Pharmaceuticals.svg);
  -webkit-mask: url(./Pharmaceuticals.svg);
}

.pictogram-naturallanguageprocessingsearch {
  mask: url(./NaturalLanguageProcessingSearch.svg);
  -webkit-mask: url(./NaturalLanguageProcessingSearch.svg);
}

.pictogram-consumermarkets {
  mask: url(./ConsumerMarkets.svg);
  -webkit-mask: url(./ConsumerMarkets.svg);
}

.pictogram-horizontalvertical {
  mask: url(./HorizontalVertical.svg);
  -webkit-mask: url(./HorizontalVertical.svg);
}

.pictogram-sales {
  mask: url(./Sales.svg);
  -webkit-mask: url(./Sales.svg);
}

.pictogram-soldier {
  mask: url(./Soldier.svg);
  -webkit-mask: url(./Soldier.svg);
}

.pictogram-solarenergy {
  mask: url(./SolarEnergy.svg);
  -webkit-mask: url(./SolarEnergy.svg);
}

.pictogram-vsdx {
  mask: url(./VSDX.svg);
  -webkit-mask: url(./VSDX.svg);
}

.pictogram-volume {
  mask: url(./Volume.svg);
  -webkit-mask: url(./Volume.svg);
}

.pictogram-spiderweb {
  mask: url(./SpiderWeb.svg);
  -webkit-mask: url(./SpiderWeb.svg);
}

.pictogram-vision {
  mask: url(./Vision.svg);
  -webkit-mask: url(./Vision.svg);
}

.pictogram-no {
  mask: url(./No.svg);
  -webkit-mask: url(./No.svg);
}

.pictogram-benchmarking {
  mask: url(./Benchmarking.svg);
  -webkit-mask: url(./Benchmarking.svg);
}

.pictogram-documentclassification {
  mask: url(./DocumentClassification.svg);
  -webkit-mask: url(./DocumentClassification.svg);
}

.pictogram-cardoor {
  mask: url(./CarDoor.svg);
  -webkit-mask: url(./CarDoor.svg);
}

.pictogram-thecloud {
  mask: url(./TheCloud.svg);
  -webkit-mask: url(./TheCloud.svg);
}

.pictogram-britishpound {
  mask: url(./BritishPound.svg);
  -webkit-mask: url(./BritishPound.svg);
}

.pictogram-satellite {
  mask: url(./Satellite.svg);
  -webkit-mask: url(./Satellite.svg);
}

.pictogram-namecard {
  mask: url(./Namecard.svg);
  -webkit-mask: url(./Namecard.svg);
}

.pictogram-taxi {
  mask: url(./Taxi.svg);
  -webkit-mask: url(./Taxi.svg);
}

.pictogram-book {
  mask: url(./Book.svg);
  -webkit-mask: url(./Book.svg);
}

.pictogram-electronicpayment {
  mask: url(./ElectronicPayment.svg);
  -webkit-mask: url(./ElectronicPayment.svg);
}

.pictogram-psd {
  mask: url(./PSD.svg);
  -webkit-mask: url(./PSD.svg);
}

.pictogram-eventstaff {
  mask: url(./EventStaff.svg);
  -webkit-mask: url(./EventStaff.svg);
}

.pictogram-pressure {
  mask: url(./Pressure.svg);
  -webkit-mask: url(./Pressure.svg);
}

.pictogram-dashboard {
  mask: url(./Dashboard.svg);
  -webkit-mask: url(./Dashboard.svg);
}

.pictogram-kpi {
  mask: url(./KPI.svg);
  -webkit-mask: url(./KPI.svg);
}

.pictogram-magnifyingglass {
  mask: url(./MagnifyingGlass.svg);
  -webkit-mask: url(./MagnifyingGlass.svg);
}

.pictogram-gif {
  mask: url(./GIF.svg);
  -webkit-mask: url(./GIF.svg);
}

.pictogram-transaction {
  mask: url(./Transaction.svg);
  -webkit-mask: url(./Transaction.svg);
}

.pictogram-facemask {
  mask: url(./FaceMask.svg);
  -webkit-mask: url(./FaceMask.svg);
}

.pictogram-flag {
  mask: url(./Flag.svg);
  -webkit-mask: url(./Flag.svg);
}

.pictogram-sustainableproducts {
  mask: url(./SustainableProducts.svg);
  -webkit-mask: url(./SustainableProducts.svg);
}

.pictogram-exchange {
  mask: url(./Exchange.svg);
  -webkit-mask: url(./Exchange.svg);
}

.pictogram-observation {
  mask: url(./Observation.svg);
  -webkit-mask: url(./Observation.svg);
}

.pictogram-businesscard {
  mask: url(./BusinessCard.svg);
  -webkit-mask: url(./BusinessCard.svg);
}

.pictogram-arvrwearables {
  mask: url(./ARVRWearables.svg);
  -webkit-mask: url(./ARVRWearables.svg);
}

.pictogram-medicalservicesmarket {
  mask: url(./MedicalServicesMarket.svg);
  -webkit-mask: url(./MedicalServicesMarket.svg);
}

.pictogram-supplychain {
  mask: url(./SupplyChain.svg);
  -webkit-mask: url(./SupplyChain.svg);
}

.pictogram-meeting {
  mask: url(./Meeting.svg);
  -webkit-mask: url(./Meeting.svg);
}

.pictogram-stealthjet {
  mask: url(./StealthJet.svg);
  -webkit-mask: url(./StealthJet.svg);
}

.pictogram-farming {
  mask: url(./Farming.svg);
  -webkit-mask: url(./Farming.svg);
}

.pictogram-border {
  mask: url(./Border.svg);
  -webkit-mask: url(./Border.svg);
}

.pictogram-cashflow {
  mask: url(./CashFlow.svg);
  -webkit-mask: url(./CashFlow.svg);
}

.pictogram-unicorn {
  mask: url(./Unicorn.svg);
  -webkit-mask: url(./Unicorn.svg);
}

.pictogram-downtrend {
  mask: url(./Downtrend.svg);
  -webkit-mask: url(./Downtrend.svg);
}

.pictogram-wireless {
  mask: url(./Wireless.svg);
  -webkit-mask: url(./Wireless.svg);
}

.pictogram-hospital {
  mask: url(./Hospital.svg);
  -webkit-mask: url(./Hospital.svg);
}

.pictogram-discountedpaybackperiod {
  mask: url(./DiscountedPaybackPeriod.svg);
  -webkit-mask: url(./DiscountedPaybackPeriod.svg);
}

.pictogram-connectedtruck {
  mask: url(./ConnectedTruck.svg);
  -webkit-mask: url(./ConnectedTruck.svg);
}

.pictogram-piechart {
  mask: url(./PieChart.svg);
  -webkit-mask: url(./PieChart.svg);
}

.pictogram-internalmedicine {
  mask: url(./InternalMedicine.svg);
  -webkit-mask: url(./InternalMedicine.svg);
}

.pictogram-roadmap {
  mask: url(./Roadmap.svg);
  -webkit-mask: url(./Roadmap.svg);
}

.pictogram-translation {
  mask: url(./Translation.svg);
  -webkit-mask: url(./Translation.svg);
}

.pictogram-internet {
  mask: url(./Internet.svg);
  -webkit-mask: url(./Internet.svg);
}

.pictogram-reduce {
  mask: url(./Reduce.svg);
  -webkit-mask: url(./Reduce.svg);
}

.pictogram-charity {
  mask: url(./Charity.svg);
  -webkit-mask: url(./Charity.svg);
}

.pictogram-financialsvc-banking-pound {
  mask: url(./FinancialSvc-Banking-Pound.svg);
  -webkit-mask: url(./FinancialSvc-Banking-Pound.svg);
}

.pictogram-wealth {
  mask: url(./Wealth.svg);
  -webkit-mask: url(./Wealth.svg);
}

.pictogram-idea-invention {
  mask: url(./Idea-Invention.svg);
  -webkit-mask: url(./Idea-Invention.svg);
}

.pictogram-world {
  mask: url(./World.svg);
  -webkit-mask: url(./World.svg);
}

.pictogram-operatingtheatre {
  mask: url(./OperatingTheatre.svg);
  -webkit-mask: url(./OperatingTheatre.svg);
}

.pictogram-maintenance {
  mask: url(./Maintenance.svg);
  -webkit-mask: url(./Maintenance.svg);
}

.pictogram-statsnumbersdiscount {
  mask: url(./StatsNumbersDiscount.svg);
  -webkit-mask: url(./StatsNumbersDiscount.svg);
}

.pictogram-quality-recognition {
  mask: url(./Quality-Recognition.svg);
  -webkit-mask: url(./Quality-Recognition.svg);
}

.pictogram-cryptoexchange {
  mask: url(./CryptoExchange.svg);
  -webkit-mask: url(./CryptoExchange.svg);
}

.pictogram-documentregulations {
  mask: url(./DocumentRegulations.svg);
  -webkit-mask: url(./DocumentRegulations.svg);
}

.pictogram-jpeg {
  mask: url(./JPEG.svg);
  -webkit-mask: url(./JPEG.svg);
}

.pictogram-netdebtcash {
  mask: url(./NetDebtCash.svg);
  -webkit-mask: url(./NetDebtCash.svg);
}

.pictogram-tank {
  mask: url(./Tank.svg);
  -webkit-mask: url(./Tank.svg);
}

.pictogram-stormsweather {
  mask: url(./StormsWeather.svg);
  -webkit-mask: url(./StormsWeather.svg);
}

.pictogram-chatbot {
  mask: url(./Chatbot.svg);
  -webkit-mask: url(./Chatbot.svg);
}

.pictogram-momentum {
  mask: url(./Momentum.svg);
  -webkit-mask: url(./Momentum.svg);
}

.pictogram-building-homesecurity {
  mask: url(./Building-HomeSecurity.svg);
  -webkit-mask: url(./Building-HomeSecurity.svg);
}

.pictogram-software {
  mask: url(./Software.svg);
  -webkit-mask: url(./Software.svg);
}

.pictogram-fish {
  mask: url(./Fish.svg);
  -webkit-mask: url(./Fish.svg);
}

.pictogram-gold {
  mask: url(./Gold.svg);
  -webkit-mask: url(./Gold.svg);
}

.pictogram-inbound {
  mask: url(./Inbound.svg);
  -webkit-mask: url(./Inbound.svg);
}

.pictogram-emergencydept {
  mask: url(./EmergencyDept.svg);
  -webkit-mask: url(./EmergencyDept.svg);
}

.pictogram-cashregister {
  mask: url(./CashRegister.svg);
  -webkit-mask: url(./CashRegister.svg);
}

.pictogram-infinityloop {
  mask: url(./InfinityLoop.svg);
  -webkit-mask: url(./InfinityLoop.svg);
}

.pictogram-giving-donating {
  mask: url(./Giving-Donating.svg);
  -webkit-mask: url(./Giving-Donating.svg);
}

.pictogram-paybackperiod {
  mask: url(./PaybackPeriod.svg);
  -webkit-mask: url(./PaybackPeriod.svg);
}

.pictogram-externalauditortraining {
  mask: url(./ExternalAuditorTraining.svg);
  -webkit-mask: url(./ExternalAuditorTraining.svg);
}

.pictogram-usdollar {
  mask: url(./USDollar.svg);
  -webkit-mask: url(./USDollar.svg);
}

.pictogram-surgery {
  mask: url(./Surgery.svg);
  -webkit-mask: url(./Surgery.svg);
}

.pictogram-iotsmartsensor {
  mask: url(./IOTSmartSensor.svg);
  -webkit-mask: url(./IOTSmartSensor.svg);
}

.pictogram-firsthospitalvisit {
  mask: url(./FirstHospitalVisit.svg);
  -webkit-mask: url(./FirstHospitalVisit.svg);
}

.pictogram-digitalwallet {
  mask: url(./DigitalWallet.svg);
  -webkit-mask: url(./DigitalWallet.svg);
}

.pictogram-siren {
  mask: url(./Siren.svg);
  -webkit-mask: url(./Siren.svg);
}

.pictogram-tshirt {
  mask: url(./TShirt.svg);
  -webkit-mask: url(./TShirt.svg);
}

.pictogram-cargohandling {
  mask: url(./CargoHandling.svg);
  -webkit-mask: url(./CargoHandling.svg);
}

.pictogram-csv {
  mask: url(./CSV.svg);
  -webkit-mask: url(./CSV.svg);
}

.pictogram-locationnavigationsystem {
  mask: url(./LocationNavigationSystem.svg);
  -webkit-mask: url(./LocationNavigationSystem.svg);
}

.pictogram-xlsx {
  mask: url(./XLSX.svg);
  -webkit-mask: url(./XLSX.svg);
}

.pictogram-breathe {
  mask: url(./Breathe.svg);
  -webkit-mask: url(./Breathe.svg);
}

.pictogram-ultrasound {
  mask: url(./Ultrasound.svg);
  -webkit-mask: url(./Ultrasound.svg);
}

.pictogram-websearch {
  mask: url(./WebSearch.svg);
  -webkit-mask: url(./WebSearch.svg);
}

.pictogram-spotlight {
  mask: url(./Spotlight.svg);
  -webkit-mask: url(./Spotlight.svg);
}

.pictogram-industrialproducts {
  mask: url(./IndustrialProducts.svg);
  -webkit-mask: url(./IndustrialProducts.svg);
}

.pictogram-bmp {
  mask: url(./BMP.svg);
  -webkit-mask: url(./BMP.svg);
}

.pictogram-hurdle-roadblock {
  mask: url(./Hurdle-Roadblock.svg);
  -webkit-mask: url(./Hurdle-Roadblock.svg);
}

.pictogram-speech {
  mask: url(./Speech.svg);
  -webkit-mask: url(./Speech.svg);
}

.pictogram-messages {
  mask: url(./Messages.svg);
  -webkit-mask: url(./Messages.svg);
}

.pictogram-augmentedreality-ar {
  mask: url(./AugmentedReality-AR.svg);
  -webkit-mask: url(./AugmentedReality-AR.svg);
}

.pictogram-contract {
  mask: url(./Contract.svg);
  -webkit-mask: url(./Contract.svg);
}

.pictogram-networkingcapital {
  mask: url(./NetWorkingCapital.svg);
  -webkit-mask: url(./NetWorkingCapital.svg);
}

.pictogram-esg {
  mask: url(./ESG.svg);
  -webkit-mask: url(./ESG.svg);
}

.pictogram-oilbarrel {
  mask: url(./OilBarrel.svg);
  -webkit-mask: url(./OilBarrel.svg);
}

.pictogram-cogs {
  mask: url(./Cogs.svg);
  -webkit-mask: url(./Cogs.svg);
}

.pictogram-paybackperiod-yen {
  mask: url(./PaybackPeriod-Yen.svg);
  -webkit-mask: url(./PaybackPeriod-Yen.svg);
}

.pictogram-notification {
  mask: url(./Notification.svg);
  -webkit-mask: url(./Notification.svg);
}

.pictogram-directcosts {
  mask: url(./DirectCosts.svg);
  -webkit-mask: url(./DirectCosts.svg);
}

.pictogram-picture {
  mask: url(./Picture.svg);
  -webkit-mask: url(./Picture.svg);
}

.pictogram-qualityofmedicaltreatment {
  mask: url(./QualityofMedicalTreatment.svg);
  -webkit-mask: url(./QualityofMedicalTreatment.svg);
}

.pictogram-hospitalbed {
  mask: url(./HospitalBed.svg);
  -webkit-mask: url(./HospitalBed.svg);
}

.pictogram-unlocked {
  mask: url(./Unlocked.svg);
  -webkit-mask: url(./Unlocked.svg);
}

.pictogram-homegarden {
  mask: url(./HomeGarden.svg);
  -webkit-mask: url(./HomeGarden.svg);
}

.pictogram-article {
  mask: url(./Article.svg);
  -webkit-mask: url(./Article.svg);
}

.pictogram-evolve {
  mask: url(./Evolve.svg);
  -webkit-mask: url(./Evolve.svg);
}

.pictogram-yachtluxuryassets {
  mask: url(./YachtLuxuryAssets.svg);
  -webkit-mask: url(./YachtLuxuryAssets.svg);
}

.pictogram-deeplearning {
  mask: url(./DeepLearning.svg);
  -webkit-mask: url(./DeepLearning.svg);
}

.pictogram-environmentregeneration {
  mask: url(./EnvironmentRegeneration.svg);
  -webkit-mask: url(./EnvironmentRegeneration.svg);
}

.pictogram-speaker {
  mask: url(./Speaker.svg);
  -webkit-mask: url(./Speaker.svg);
}

.pictogram-cardiology {
  mask: url(./Cardiology.svg);
  -webkit-mask: url(./Cardiology.svg);
}

.pictogram-outofpocketexpense {
  mask: url(./OutofPocketExpense.svg);
  -webkit-mask: url(./OutofPocketExpense.svg);
}

.pictogram-feedback {
  mask: url(./Feedback.svg);
  -webkit-mask: url(./Feedback.svg);
}

.pictogram-risk {
  mask: url(./Risk.svg);
  -webkit-mask: url(./Risk.svg);
}

.pictogram-truck {
  mask: url(./Truck.svg);
  -webkit-mask: url(./Truck.svg);
}

.pictogram-collaboration {
  mask: url(./Collaboration.svg);
  -webkit-mask: url(./Collaboration.svg);
}

.pictogram-frankingmachines-postage {
  mask: url(./FrankingMachines-Postage.svg);
  -webkit-mask: url(./FrankingMachines-Postage.svg);
}

.pictogram-ocean {
  mask: url(./Ocean.svg);
  -webkit-mask: url(./Ocean.svg);
}

.pictogram-number-1 {
  mask: url(./Number-1.svg);
  -webkit-mask: url(./Number-1.svg);
}

.pictogram-dataeconomy {
  mask: url(./DataEconomy.svg);
  -webkit-mask: url(./DataEconomy.svg);
}

.pictogram-cybersecurity {
  mask: url(./Cybersecurity.svg);
  -webkit-mask: url(./Cybersecurity.svg);
}

.pictogram-netdebt-cash-yen {
  mask: url(./NetDebt-Cash-Yen.svg);
  -webkit-mask: url(./NetDebt-Cash-Yen.svg);
}

.pictogram-engineeringconstruction {
  mask: url(./EngineeringConstruction.svg);
  -webkit-mask: url(./EngineeringConstruction.svg);
}

.pictogram-saving {
  mask: url(./Saving.svg);
  -webkit-mask: url(./Saving.svg);
}

.pictogram-flipchart {
  mask: url(./Flipchart.svg);
  -webkit-mask: url(./Flipchart.svg);
}

.pictogram-security {
  mask: url(./Security.svg);
  -webkit-mask: url(./Security.svg);
}

.pictogram-manufacturing {
  mask: url(./Manufacturing.svg);
  -webkit-mask: url(./Manufacturing.svg);
}

.pictogram-safetypayment {
  mask: url(./SafetyPayment.svg);
  -webkit-mask: url(./SafetyPayment.svg);
}

.pictogram-stress {
  mask: url(./Stress.svg);
  -webkit-mask: url(./Stress.svg);
}

.pictogram-talentworkforce {
  mask: url(./TalentWorkforce.svg);
  -webkit-mask: url(./TalentWorkforce.svg);
}

.pictogram-thumbprint {
  mask: url(./Thumbprint.svg);
  -webkit-mask: url(./Thumbprint.svg);
}

.pictogram-operatingexpense-yen {
  mask: url(./OperatingExpense-Yen.svg);
  -webkit-mask: url(./OperatingExpense-Yen.svg);
}

.pictogram-presenter {
  mask: url(./Presenter.svg);
  -webkit-mask: url(./Presenter.svg);
}

.pictogram-media {
  mask: url(./Media.svg);
  -webkit-mask: url(./Media.svg);
}

.pictogram-policeofficer {
  mask: url(./PoliceOfficer.svg);
  -webkit-mask: url(./PoliceOfficer.svg);
}

.pictogram-calculatortax {
  mask: url(./CalculatorTax.svg);
  -webkit-mask: url(./CalculatorTax.svg);
}

.pictogram-bus {
  mask: url(./Bus.svg);
  -webkit-mask: url(./Bus.svg);
}

.pictogram-attachment {
  mask: url(./Attachment.svg);
  -webkit-mask: url(./Attachment.svg);
}

.pictogram-selfdrivingcar {
  mask: url(./SelfDrivingCar.svg);
  -webkit-mask: url(./SelfDrivingCar.svg);
}

.pictogram-blockchain {
  mask: url(./Blockchain.svg);
  -webkit-mask: url(./Blockchain.svg);
}

.pictogram-biodiversity {
  mask: url(./Biodiversity.svg);
  -webkit-mask: url(./Biodiversity.svg);
}

.pictogram-steelforging {
  mask: url(./SteelForging.svg);
  -webkit-mask: url(./SteelForging.svg);
}

.pictogram-network {
  mask: url(./Network.svg);
  -webkit-mask: url(./Network.svg);
}

.pictogram-customers {
  mask: url(./Customers.svg);
  -webkit-mask: url(./Customers.svg);
}

.pictogram-art {
  mask: url(./Art.svg);
  -webkit-mask: url(./Art.svg);
}

.pictogram-star {
  mask: url(./Star.svg);
  -webkit-mask: url(./Star.svg);
}

.pictogram-laboratory {
  mask: url(./Laboratory.svg);
  -webkit-mask: url(./Laboratory.svg);
}

.pictogram-checklist {
  mask: url(./Checklist.svg);
  -webkit-mask: url(./Checklist.svg);
}

.pictogram-mining {
  mask: url(./Mining.svg);
  -webkit-mask: url(./Mining.svg);
}

.pictogram-naturalgas {
  mask: url(./NaturalGas.svg);
  -webkit-mask: url(./NaturalGas.svg);
}

.pictogram-packaging {
  mask: url(./Packaging.svg);
  -webkit-mask: url(./Packaging.svg);
}

.pictogram-qualityofearnings-yen {
  mask: url(./QualityofEarnings-Yen.svg);
  -webkit-mask: url(./QualityofEarnings-Yen.svg);
}

.pictogram-contactless {
  mask: url(./Contactless.svg);
  -webkit-mask: url(./Contactless.svg);
}

.pictogram-cyclistbike {
  mask: url(./CyclistBike.svg);
  -webkit-mask: url(./CyclistBike.svg);
}

.pictogram-remanufacture {
  mask: url(./Remanufacture.svg);
  -webkit-mask: url(./Remanufacture.svg);
}

.pictogram-education {
  mask: url(./Education.svg);
  -webkit-mask: url(./Education.svg);
}

.pictogram-forecasting {
  mask: url(./Forecasting.svg);
  -webkit-mask: url(./Forecasting.svg);
}

.pictogram-pdf {
  mask: url(./PDF.svg);
  -webkit-mask: url(./PDF.svg);
}

.pictogram-oilplatform {
  mask: url(./OilPlatform.svg);
  -webkit-mask: url(./OilPlatform.svg);
}

.pictogram-compass {
  mask: url(./Compass.svg);
  -webkit-mask: url(./Compass.svg);
}

.pictogram-automation {
  mask: url(./Automation.svg);
  -webkit-mask: url(./Automation.svg);
}

.pictogram-mouse-click-arrow {
  mask: url(./Mouse-Click-Arrow.svg);
  -webkit-mask: url(./Mouse-Click-Arrow.svg);
}

.pictogram-farmer {
  mask: url(./Farmer.svg);
  -webkit-mask: url(./Farmer.svg);
}

.pictogram-awm-yen {
  mask: url(./AWM-Yen.svg);
  -webkit-mask: url(./AWM-Yen.svg);
}

.pictogram-housinghospitalitycities {
  mask: url(./HousingHospitalityCities.svg);
  -webkit-mask: url(./HousingHospitalityCities.svg);
}

.pictogram-infrastructure {
  mask: url(./Infrastructure.svg);
  -webkit-mask: url(./Infrastructure.svg);
}

.pictogram-carmanufacturing {
  mask: url(./CarManufacturing.svg);
  -webkit-mask: url(./CarManufacturing.svg);
}

.pictogram-windfarmenergy {
  mask: url(./WindFarmEnergy.svg);
  -webkit-mask: url(./WindFarmEnergy.svg);
}

.pictogram-computerchip {
  mask: url(./ComputerChip.svg);
  -webkit-mask: url(./ComputerChip.svg);
}

.pictogram-indirectcosts {
  mask: url(./IndirectCosts.svg);
  -webkit-mask: url(./IndirectCosts.svg);
}

.pictogram-indirectcosts-yen {
  mask: url(./IndirectCosts-Yen.svg);
  -webkit-mask: url(./IndirectCosts-Yen.svg);
}

.pictogram-winning {
  mask: url(./Winning.svg);
  -webkit-mask: url(./Winning.svg);
}

.pictogram-crab {
  mask: url(./Crab.svg);
  -webkit-mask: url(./Crab.svg);
}

.pictogram-whitegoods {
  mask: url(./WhiteGoods.svg);
  -webkit-mask: url(./WhiteGoods.svg);
}

.pictogram-numbers-16 {
  mask: url(./Numbers-16.svg);
  -webkit-mask: url(./Numbers-16.svg);
}

.pictogram-clock {
  mask: url(./Clock.svg);
  -webkit-mask: url(./Clock.svg);
}

.pictogram-trust {
  mask: url(./Trust.svg);
  -webkit-mask: url(./Trust.svg);
}

.pictogram-operatingmodel {
  mask: url(./OperatingModel.svg);
  -webkit-mask: url(./OperatingModel.svg);
}

.pictogram-phone {
  mask: url(./Phone.svg);
  -webkit-mask: url(./Phone.svg);
}

.pictogram-oilgasenergy {
  mask: url(./OilGasEnergy.svg);
  -webkit-mask: url(./OilGasEnergy.svg);
}

.pictogram-lowercosts {
  mask: url(./LowerCosts.svg);
  -webkit-mask: url(./LowerCosts.svg);
}

.pictogram-healthandsafety {
  mask: url(./HealthAndSafety.svg);
  -webkit-mask: url(./HealthAndSafety.svg);
}

.pictogram-announcement {
  mask: url(./Announcement.svg);
  -webkit-mask: url(./Announcement.svg);
}

.pictogram-tractor {
  mask: url(./Tractor.svg);
  -webkit-mask: url(./Tractor.svg);
}

.pictogram-drone {
  mask: url(./Drone.svg);
  -webkit-mask: url(./Drone.svg);
}

.pictogram-growtheconomy {
  mask: url(./GrowthEconomy.svg);
  -webkit-mask: url(./GrowthEconomy.svg);
}

.pictogram-insurance {
  mask: url(./Insurance.svg);
  -webkit-mask: url(./Insurance.svg);
}

.pictogram-sleep-rest {
  mask: url(./Sleep-Rest.svg);
  -webkit-mask: url(./Sleep-Rest.svg);
}

.pictogram-decisionsdistribution {
  mask: url(./DecisionsDistribution.svg);
  -webkit-mask: url(./DecisionsDistribution.svg);
}

.pictogram-flood {
  mask: url(./Flood.svg);
  -webkit-mask: url(./Flood.svg);
}

.pictogram-starch {
  mask: url(./Starch.svg);
  -webkit-mask: url(./Starch.svg);
}

.pictogram-justicelegal {
  mask: url(./JusticeLegal.svg);
  -webkit-mask: url(./JusticeLegal.svg);
}

.pictogram-gift {
  mask: url(./Gift.svg);
  -webkit-mask: url(./Gift.svg);
}

.pictogram-car {
  mask: url(./Car.svg);
  -webkit-mask: url(./Car.svg);
}

.pictogram-numbers-17 {
  mask: url(./Numbers-17.svg);
  -webkit-mask: url(./Numbers-17.svg);
}

.pictogram-conversation {
  mask: url(./Conversation.svg);
  -webkit-mask: url(./Conversation.svg);
}

.pictogram-share {
  mask: url(./Share.svg);
  -webkit-mask: url(./Share.svg);
}

.pictogram-litter {
  mask: url(./Litter.svg);
  -webkit-mask: url(./Litter.svg);
}

.pictogram-healthindustries {
  mask: url(./HealthIndustries.svg);
  -webkit-mask: url(./HealthIndustries.svg);
}

.pictogram-nursingroom {
  mask: url(./NursingRoom.svg);
  -webkit-mask: url(./NursingRoom.svg);
}

.pictogram-numbers-15 {
  mask: url(./Numbers-15.svg);
  -webkit-mask: url(./Numbers-15.svg);
}

.pictogram-dentalcare {
  mask: url(./DentalCare.svg);
  -webkit-mask: url(./DentalCare.svg);
}

.pictogram-dataanalysisinvestigation {
  mask: url(./DataAnalysisInvestigation.svg);
  -webkit-mask: url(./DataAnalysisInvestigation.svg);
}

.pictogram-emotional-bewell {
  mask: url(./Emotional-BeWell.svg);
  -webkit-mask: url(./Emotional-BeWell.svg);
}

.pictogram-water {
  mask: url(./Water.svg);
  -webkit-mask: url(./Water.svg);
}

.pictogram-customize {
  mask: url(./Customize.svg);
  -webkit-mask: url(./Customize.svg);
}

.pictogram-directcosts-yen {
  mask: url(./DirectCosts-Yen.svg);
  -webkit-mask: url(./DirectCosts-Yen.svg);
}

.pictogram-electricstation {
  mask: url(./ElectricStation.svg);
  -webkit-mask: url(./ElectricStation.svg);
}

.pictogram-electricvehicle {
  mask: url(./ElectricVehicle.svg);
  -webkit-mask: url(./ElectricVehicle.svg);
}

.pictogram-marketing {
  mask: url(./Marketing.svg);
  -webkit-mask: url(./Marketing.svg);
}

.pictogram-calendar {
  mask: url(./Calendar.svg);
  -webkit-mask: url(./Calendar.svg);
}

.pictogram-governmentpublicsector {
  mask: url(./GovernmentPublicSector.svg);
  -webkit-mask: url(./GovernmentPublicSector.svg);
}

.pictogram-laptop {
  mask: url(./Laptop.svg);
  -webkit-mask: url(./Laptop.svg);
}

.pictogram-powercable {
  mask: url(./PowerCable.svg);
  -webkit-mask: url(./PowerCable.svg);
}

.pictogram-hazardouswaste {
  mask: url(./HazardousWaste.svg);
  -webkit-mask: url(./HazardousWaste.svg);
}

.pictogram-housing-duplex {
  mask: url(./Housing-Duplex.svg);
  -webkit-mask: url(./Housing-Duplex.svg);
}

.pictogram-html {
  mask: url(./HTML.svg);
  -webkit-mask: url(./HTML.svg);
}

.pictogram-competition {
  mask: url(./Competition.svg);
  -webkit-mask: url(./Competition.svg);
}

.pictogram-numbers-14 {
  mask: url(./Numbers-14.svg);
  -webkit-mask: url(./Numbers-14.svg);
}

.pictogram-pc {
  mask: url(./PC.svg);
  -webkit-mask: url(./PC.svg);
}

.pictogram-compulsoryhealthins {
  mask: url(./CompulsoryHealthIns.svg);
  -webkit-mask: url(./CompulsoryHealthIns.svg);
}

.pictogram-desktopinterface {
  mask: url(./DesktopInterface.svg);
  -webkit-mask: url(./DesktopInterface.svg);
}

.pictogram-handbook-manual {
  mask: url(./Handbook-Manual.svg);
  -webkit-mask: url(./Handbook-Manual.svg);
}

.pictogram-dermatology {
  mask: url(./Dermatology.svg);
  -webkit-mask: url(./Dermatology.svg);
}

.pictogram-webdesign {
  mask: url(./WebDesign.svg);
  -webkit-mask: url(./WebDesign.svg);
}

.pictogram-database-cloud {
  mask: url(./Database-Cloud.svg);
  -webkit-mask: url(./Database-Cloud.svg);
}

.pictogram-coffeemug {
  mask: url(./CoffeeMug.svg);
  -webkit-mask: url(./CoffeeMug.svg);
}

.pictogram-customercentered {
  mask: url(./CustomerCentered.svg);
  -webkit-mask: url(./CustomerCentered.svg);
}

.pictogram-supplier {
  mask: url(./Supplier.svg);
  -webkit-mask: url(./Supplier.svg);
}

.pictogram-skillseducationidea {
  mask: url(./SkillsEducationIdea.svg);
  -webkit-mask: url(./SkillsEducationIdea.svg);
}

.pictogram-numbers-10 {
  mask: url(./Numbers-10.svg);
  -webkit-mask: url(./Numbers-10.svg);
}

.pictogram-carseat {
  mask: url(./CarSeat.svg);
  -webkit-mask: url(./CarSeat.svg);
}

.pictogram-awm {
  mask: url(./AWM.svg);
  -webkit-mask: url(./AWM.svg);
}

.pictogram-shoes {
  mask: url(./Shoes.svg);
  -webkit-mask: url(./Shoes.svg);
}

.pictogram-military {
  mask: url(./Military.svg);
  -webkit-mask: url(./Military.svg);
}

.pictogram-ebitda {
  mask: url(./EBITDA.svg);
  -webkit-mask: url(./EBITDA.svg);
}

.pictogram-newsreport {
  mask: url(./NewsReport.svg);
  -webkit-mask: url(./NewsReport.svg);
}

.pictogram-balloon {
  mask: url(./Balloon.svg);
  -webkit-mask: url(./Balloon.svg);
}

.pictogram-moveout {
  mask: url(./MoveOut.svg);
  -webkit-mask: url(./MoveOut.svg);
}

.pictogram-businessecosystem {
  mask: url(./BusinessEcosystem.svg);
  -webkit-mask: url(./BusinessEcosystem.svg);
}

.pictogram-positivechangeinmetricsdeltanudge {
  mask: url(./PositiveChangeinMetricsDeltaNudge.svg);
  -webkit-mask: url(./PositiveChangeinMetricsDeltaNudge.svg);
}

.pictogram-medicalmaterials {
  mask: url(./MedicalMaterials.svg);
  -webkit-mask: url(./MedicalMaterials.svg);
}

.pictogram-employee {
  mask: url(./Employee.svg);
  -webkit-mask: url(./Employee.svg);
}

.pictogram-pptx {
  mask: url(./PPTX.svg);
  -webkit-mask: url(./PPTX.svg);
}

.pictogram-startupentrepreneurialspirit {
  mask: url(./StartupEntrepreneurialSpirit.svg);
  -webkit-mask: url(./StartupEntrepreneurialSpirit.svg);
}

.pictogram-cashflow-yen {
  mask: url(./CashFlow-Yen.svg);
  -webkit-mask: url(./CashFlow-Yen.svg);
}

.pictogram-assettransfer {
  mask: url(./AssetTransfer.svg);
  -webkit-mask: url(./AssetTransfer.svg);
}

.pictogram-artillery {
  mask: url(./Artillery.svg);
  -webkit-mask: url(./Artillery.svg);
}

.pictogram-flowchart {
  mask: url(./Flowchart.svg);
  -webkit-mask: url(./Flowchart.svg);
}

.pictogram-nurse {
  mask: url(./Nurse.svg);
  -webkit-mask: url(./Nurse.svg);
}

.pictogram-atom {
  mask: url(./Atom.svg);
  -webkit-mask: url(./Atom.svg);
}

.pictogram-multisteplogic {
  mask: url(./MultiStepLogic.svg);
  -webkit-mask: url(./MultiStepLogic.svg);
}

.pictogram-affirmation {
  mask: url(./Affirmation.svg);
  -webkit-mask: url(./Affirmation.svg);
}

.pictogram-processor {
  mask: url(./Processor.svg);
  -webkit-mask: url(./Processor.svg);
}

.pictogram-numbers-11 {
  mask: url(./Numbers-11.svg);
  -webkit-mask: url(./Numbers-11.svg);
}

.pictogram-facialrecognition {
  mask: url(./FacialRecognition.svg);
  -webkit-mask: url(./FacialRecognition.svg);
}

.pictogram-locked {
  mask: url(./Locked.svg);
  -webkit-mask: url(./Locked.svg);
}

.pictogram-female {
  mask: url(./Female.svg);
  -webkit-mask: url(./Female.svg);
}

.pictogram-revenue {
  mask: url(./Revenue.svg);
  -webkit-mask: url(./Revenue.svg);
}

.pictogram-complementaryhealthins {
  mask: url(./ComplementaryHealthIns.svg);
  -webkit-mask: url(./ComplementaryHealthIns.svg);
}

.pictogram-orthopedics {
  mask: url(./Orthopedics.svg);
  -webkit-mask: url(./Orthopedics.svg);
}

.pictogram-numbers-13 {
  mask: url(./Numbers-13.svg);
  -webkit-mask: url(./Numbers-13.svg);
}

.pictogram-meal-grain {
  mask: url(./Meal-Grain.svg);
  -webkit-mask: url(./Meal-Grain.svg);
}

.pictogram-segmentation {
  mask: url(./Segmentation.svg);
  -webkit-mask: url(./Segmentation.svg);
}

.pictogram-optimisation {
  mask: url(./Optimisation.svg);
  -webkit-mask: url(./Optimisation.svg);
}

.pictogram-numbers-8 {
  mask: url(./Numbers-8.svg);
  -webkit-mask: url(./Numbers-8.svg);
}

.pictogram-capitalexpenditures {
  mask: url(./CapitalExpenditures.svg);
  -webkit-mask: url(./CapitalExpenditures.svg);
}

.pictogram-cryptoassets {
  mask: url(./CryptoAssets.svg);
  -webkit-mask: url(./CryptoAssets.svg);
}

.pictogram-celebration {
  mask: url(./Celebration.svg);
  -webkit-mask: url(./Celebration.svg);
}

.pictogram-distributor {
  mask: url(./Distributor.svg);
  -webkit-mask: url(./Distributor.svg);
}

.pictogram-heat {
  mask: url(./Heat.svg);
  -webkit-mask: url(./Heat.svg);
}

.pictogram-auditing {
  mask: url(./Auditing.svg);
  -webkit-mask: url(./Auditing.svg);
}

.pictogram-beauty-personalcare {
  mask: url(./Beauty-PersonalCare.svg);
  -webkit-mask: url(./Beauty-PersonalCare.svg);
}

.pictogram-financialservices-banking1-yen {
  mask: url(./FinancialServices-Banking1-Yen.svg);
  -webkit-mask: url(./FinancialServices-Banking1-Yen.svg);
}

.pictogram-gastroenterology {
  mask: url(./Gastroenterology.svg);
  -webkit-mask: url(./Gastroenterology.svg);
}

.pictogram-png {
  mask: url(./PNG.svg);
  -webkit-mask: url(./PNG.svg);
}

.pictogram-xray-radiology {
  mask: url(./XRay-Radiology.svg);
  -webkit-mask: url(./XRay-Radiology.svg);
}

.pictogram-audioimagevideoprocessing {
  mask: url(./AudioImageVideoProcessing.svg);
  -webkit-mask: url(./AudioImageVideoProcessing.svg);
}

.pictogram-japaneseyen {
  mask: url(./JapaneseYen.svg);
  -webkit-mask: url(./JapaneseYen.svg);
}

.pictogram-film {
  mask: url(./Film.svg);
  -webkit-mask: url(./Film.svg);
}

.pictogram-print {
  mask: url(./Print.svg);
  -webkit-mask: url(./Print.svg);
}

.pictogram-cargoship {
  mask: url(./CargoShip.svg);
  -webkit-mask: url(./CargoShip.svg);
}

.pictogram-patientsafety {
  mask: url(./PatientSafety.svg);
  -webkit-mask: url(./PatientSafety.svg);
}

.pictogram-hospitalitydining {
  mask: url(./HospitalityDining.svg);
  -webkit-mask: url(./HospitalityDining.svg);
}

.pictogram-numbers-9 {
  mask: url(./Numbers-9.svg);
  -webkit-mask: url(./Numbers-9.svg);
}

.pictogram-ribbon {
  mask: url(./Ribbon.svg);
  -webkit-mask: url(./Ribbon.svg);
}

.pictogram-immigration {
  mask: url(./Immigration.svg);
  -webkit-mask: url(./Immigration.svg);
}

.pictogram-graphicdesign {
  mask: url(./GraphicDesign.svg);
  -webkit-mask: url(./GraphicDesign.svg);
}

.pictogram-numbers-12 {
  mask: url(./Numbers-12.svg);
  -webkit-mask: url(./Numbers-12.svg);
}

.pictogram-parkassistance {
  mask: url(./ParkAssistance.svg);
  -webkit-mask: url(./ParkAssistance.svg);
}

.pictogram-disabled {
  mask: url(./Disabled.svg);
  -webkit-mask: url(./Disabled.svg);
}

.pictogram-cleaning {
  mask: url(./Cleaning.svg);
  -webkit-mask: url(./Cleaning.svg);
}